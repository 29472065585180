.inlineThumbnailListing {

    &.bg-deepblue,
    &.bg-blue,
    &.bg-dimgray,
    &.bg-orange {
        color: #fff;
    }

    .heading {
        margin-bottom: 50px;
        @include media($desktop) { margin-bottom: 75px; }
    }

    .pf-rw {
        @include row(20px);
        .pf-col { @include media($large-screen) { width: 50%; } }
    }

    .pf-item {
        @include row(15px);
        margin-bottom: 20px;
        .pfi.image { width: 30%; }
        .pfi.info { width: 70%; }
        @include media($medium-screen) {
            .pfi.image { width: 25%; }
            .pfi.info { width: 75%; }
        }
        @include media($tablet) {
            .pfi.image { width: 20%; }
            .pfi.info { width: 80%; }
        }
        @include media($large-screen)  {
            .pfi.image { width: 25%; }
            .pfi.info { width: 75%; }
        }
        @include media($desktop)  {
            .pfi.image { width: 20%; }
            .pfi.info { width: 80%; }
        }

        &.mid-info { align-items: center; }
    }

    .pfi.info {
        display:flex;
        align-items: center;
        h3 {
            margin-bottom: 0;
            font-size: 20px;
        }
        h3 + .brief { margin-top: 20px; }
    }

    & + .enquiry:before {
        content: '';
        @include absPos(0,0,auto,0);
        margin: auto;
        display: block;
        width: 100%;
        height: 50%;
    }
    &.bg-deepblue + .enquiry.layout-box:before { background-color: var(--primary); }
    &.bg-blue + .enquiry.layout-box:before { background-color: var(--primary-1); }
    &.bg-dimgray + .enquiry.layout-box:before { background-color: var(--dimgray); }
    &.bg-gray + .enquiry.layout-box:before { background-color: var(--gray); }
    &.bg-orange + .enquiry.layout-box:before { background-color: var(--secondary); }

}