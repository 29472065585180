/** buttons in editor **/
.form-group {
    position: relative;
    margin-bottom: 20px;
    transition: all 100ms ease;

    label {
        display: block;
        margin-bottom: 5px;
        font-weight: 600;
    }

    &.error .form-control,
    &.error .selectric {
        box-shadow: 0 0 5px 0 red;
    }
}

form.no-label label { display: none !important; }

select, textarea, [type=text], [type=email], [type=password], [type=submit], button.btn, [type=number] {
    border-width: 0px;
    font-size: 14px;
    line-height: 1;
    font-family: var(--font-primary);
}

span.req { color: red; }

.form-control {
    display: block;
    width: 100%;
    padding: 8px 15px;
    @include media($tablet) {
        padding: 10px 18px;
        &.big { padding: 15px 20px; }
    }
    transition: all 100ms ease;
    border: 1px solid var(--gray-2);

    &:active, &:focus {
        outline: 0;
        box-shadow: 0 0 2px 1px rgba(var(--primary-1),.25);
    }

    &.sharp { border-radius: 0; }
}

.ff-row {
    @include row(15px);
    &.narrow {
        @include row(5px);
        .ff-col.form-group { margin-bottom: 15px; }
    }
    @include media($tablet) {
        flex-wrap: nowrap !important;
        .ff-col { width: 100%; }
    }
}
.ff-form-success {
    color: green;
}
.ff-form-errors {
    color: red;
}

.ff-errors {
    margin: 5px 0 0 0;
    padding: 0;
    list-style-type: none;
    font-size: 80%;
    color: red;
    font-weight: 400;
    font-style: italic;
}

.freeform-column-content-align-right {
    text-align: right;
}
.freeform-column-content-align-center {
    text-align: center;
}
.freeform-required:after {
    content: "*";
    margin-left: 5px;
    color: red;
}


/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
/** search form common **/
	&.search-group {
		.search-field {
			width: 100%;
            /* Styles here should match selectric label which this field appears next to. */
            padding: 18px 40px 18px 15px;
            &::placeholder {
                color:#C3C3C3;
                opacity: 1;    /* reset firefox opacity */
            }
		}
		button {
			all: unset;
            @include absPos(0,0,0);
            margin: auto;
            width: 16px;
            padding: 0 14px;
            cursor: pointer;
            fill: var(--gray-2);
            @include aHover() { fill: var(--primary-1); }
			cursor: pointer;

            svg {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                width: 15px;
                fill: var(--gray-2);
                transition: all $quick ease;
            }
            @include aHover() { svg { fill: var(--primary-1); } }
		}
	}
}
label.float,
input,
textarea,
select {
    padding: 18px 20px;
    box-sizing: border-box;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
$form-field-border-radius: 6px;
input,
textarea,
select {
    text-align: left;
    font-family: var(--font-primary);
    width: 100%;
    box-sizing: border-box;
    border-radius: $form-field-border-radius;
}
.selectric{
    border-radius: $form-field-border-radius;
    .label{
        font-weight: 500;
        padding:18px 15px!important;
        color:#C3C3C3;
    }
}
.selectric-wrapper .selectric .button,
.selectric-wrapper .selectric .button:hover  {
    color:transparent !important;
}
textarea {
    min-height: 100px;
}
label.float {
    position: absolute;
    font-weight: 500;
    left: 0px;
    text-transform: capitalize;
    color: #C3C3C3;

    line-height: 1;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.js-float-wrap {
    position:relative;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 0px;
    padding: 0px;
    padding-left: 20px;
    line-height: 18px;
    font-size: 0.75em;
}

/** search forms **/
