@import "vars";

body {
    font-family: var(--font-primary);
    font-size: 15px;
    line-height: 1.3;
    color: var(--dimgray);
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
ul{
	padding-left: 15px;
}
h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-secondary);
    color: var(--primary-1); // blue
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1.3;
}

h2 {
	text-transform: uppercase;
	color: var(--primary); // navy
	font-weight: 800;
    font-size: 30px;
}

p {
    margin-top: 0;
}

a {
    color: var(--secondary-2); // pink
    transition: color $quick ease;
    &:hover {
        color: var(--primary);
    }
    &.plain-link {
        color: inherit;
        text-decoration: none;
    }
    &.hover-expand {
        transition: transform 500ms ease;
        display: inline-block;
        &:hover {
            transform: scale(1.5);
        }
    }
}

.clear-float {
    clear:both;
}

blockquote{
	position: relative;
	color:#0B2D52;
	font-size: 24px;
	&::before{
		content:url("../assets/quote-open.svg");
		display: block;
		margin: 0 auto;
		padding-bottom: 2rem;
		height: 25px;
		width: 25px;
	}

}

/***  buttons ***/
.button { // check vars
	@include button_shared;
	@include btn_primary_rev;
}
.button-orange {
    @include button_shared;
    @include orange_button;
}

.pink_underline {
    @include button_shared;
    color: var(--secondary-2); // pink
	padding: 10px 0px 8px 0px;
	border-radius: 0px;
    border-bottom: 1px solid var(--secondary-2);
    margin: auto 5px;
    @include aHover {
        color: var(--primary);
        border-color: var(--primary);
    }
}
.white_underline {
    @include button_shared;
    color: #fff;
	padding: 10px 0px 8px 0px;
	border-radius: 0px;
    border-bottom: 1px solid #fff;
    margin: auto 5px;
    @include aHover {
        color: var(--primary);
        border-color: var(--primary);
    }
}

/** list styles **/
@mixin orange-square-list {
    ul {
        padding: 0;
        list-style-type: none;
        li {
            position: relative;
            padding-left: 20px;
            line-height: 2;
            &::before {
                content: '';
                @include absPos(11px,0,0,0);
                background-color: var(--secondary);
                width: 7px;
                height: 7px;
            }
        }
    }
}

ul {
    &.tick-blocks {
        padding: 0;
	    list-style-type: none;
        display: flex;
	    flex-wrap: wrap;
	    gap: 5px;
	    justify-content: center;
	    li {
	        // mimic the tileList
	        display: inline-block;
	        background-color: #fff;
	        min-height: 240px;
	        padding: 20px;
	        height: 100%;
	        min-height: 170px;
	        width: 49%;
			box-sizing: border-box;
			@include aHover() { background-color: rgba(var(--primary-1-rgb), 0.1); }

			@include media($small-desktop) { padding: 30px; }
	        @include media($tablet){ width: 24%;}

	        // mimic h3
	        font-family: var(--font-secondary);
            font-weight: 600;
	        font-size: 20px;
	        line-height: 1;
	        color: var(--primary-1);
	        text-transform: uppercase;
	        &::before { // replace square with tick
	            content: '';
	            position: static;
	            background-color: transparent;
	            display: block;
	            width: 30px;
	            height: 30px;
	            background-image: url("../assets/checkbox.svg");
	            background-size: contain;
	            background-repeat: no-repeat;
	            margin-bottom: 20px;
	        }
	    }
    }
	
    &.three-column,
    &.tick-column,
	&.two-column {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		justify-content: space-evenly;
		padding: 0px;
		width: 100%;
		box-sizing: border-box;
		li {
		    display: inline-block;
		    padding: 30px;
		    border-left: 1px solid var(--gray-1);
		    box-sizing: border-box;
		}
	}
	&.two-column,
	&.tick-column {
	    li {
		    width: 50%;
			padding: 30px 5%;
			&:nth-of-type(odd){
				border-left: 0px;
			}
		
		}
	}

	&.tick-column li {
		position: relative;
		min-height: 100px; /* for tick */
		padding-left: 150px; /* for tick */
		border-left: 0px;
		// mimic h3
        font-family: var(--font-secondary);
        font-weight: 600;
        font-size: 20px;
        line-height: 1;
        text-transform: uppercase;
		&::before {
			/* tick */
			content:"";
			display: block;
			position: absolute;
			top: 10px;
			left: 0px;
			width: 80px;
			height: 80px;
			background-color: transparent;
			background-image: url("../assets/check-with-circle.svg");
			background-size: contain;
			background-repeat: no-repeat;
			@include mediaDown($mobile) {
                width: 60px;
			
            }
		}
		@include mediaDown($large-screen) {
			width: 80%!important;
			padding-left:110px!important;
		}
		@include mediaDown($mobile) {
			width: 100%!important;
			padding-left:80px!important;
		}
	}
	&.three-column li {
		width: 33%;
		padding: 30px 5%;
		&:nth-of-type(3n+1){
			border-left: 0px;
		}
		@include mediaDown($large-screen) {
			width: 50%;
		}
		@include mediaDown($mobile) {
			width: 100%;
		}
	}
}


/** image styles **/
img.display-left {
    float:left;
    max-width: 50%;
    margin-right:30px;
    margin-bottom:30px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:30px;
    margin-bottom:30px;
}
/** text colours **/
.text-blue {
    color: var(--primary-1) !important;
}
.text-deepblue {
    color: var(--primary);
}
.text-orange,
.match {
    color: var(--secondary);
}

.text-turquoise
{
	color:var(--tertiary);
}
.text-dimgrey{
	color:var(--dimgray);
}

.text-dirtywhite{
	color:var(--gray);
}

.text-pink{
	color: var(--secondary-2);
}


ul{
	&.check-icon{
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		li::before {
			content: url('../assets/check.svg'); 
			height: 18px;
			width: 18px;
			margin-left: -20px; 
			margin-right: 10px; 
			display: inline-block;
			flex-shrink: 0; // Prevent the icon shrinking if there is a lot of text in the LI
		} 
		li{
			padding-left: 25px;
			display: flex;
			align-items: start;
			width: 50%;
			text-align: left;
			margin: 0.5em 0;
			@media only screen and (max-width:500px){
				width: 100%;
			}
		}
	}
	&.cross-icon-list {
		@extend .check-icon;
		li::before{
			content: url("../assets/cross.svg");
		}
	}
}
.horizontal-icon-list{
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	gap:2rem;
	padding:0;
	margin:0;
	&-centered{
		@extend .horizontal-icon-list;
		justify-content: space-between;
	}
	li{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap:1rem;
		max-width: 20%;
	}
	@media only screen and (max-width:500px){
		flex-direction: column;
		li{
			max-width: 100%;
		}
	}
}
table.table-striped{
	tr:nth-child(odd){
		background-color: #C3C3C3;
	}
	td {
		padding: 0.5em;
	}
	border-collapse: collapse;
	border:transparent;
}

/** block level **/
@media only screen and (max-width:960px){
/** stack columns **/
	ul {
		&.two-column,
		&.tick-column {
		    li {
			    width: 100%;
			    border-left: 0px;
			    > * {
			        display: block;
			    }
			    img {
			        display: block;
			        width: 90px;
			    }
			}
		}
		&.two-column {
			text-align: center;
			li {
				img {
					margin: 0 auto 50px auto;
				}
			}
		}
	}
}
@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}
.one-row{
	display: flex;
	gap:2rem;
	padding:0;
	margin:0;
	list-style: none;
	align-items: center;
	flex-wrap:wrap;
}
