/** page style**/
.pager{
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    li{

        min-width: 40px;
        min-height: 40px;
        text-align: center;
        background-color: transparent;
        margin: 5px;
        a{
            display: block;
            color: var(--dimgray);
            text-decoration: none;
            line-height: 1;
            padding: 11px 5px;
            width: 100%;
            height: 100%;
            border: 1px solid var(--dimgray);
            border-radius: 7px;
            box-sizing: border-box;
        }
        &:hover {
            transition: all $quick ease;
            a {
                color: #FFF;
                background-color: var(--primary-1);
                border-color: var(--primary-1);
            }
        }
        &.active {
            background-color: var(--gray);
            &:hover a {
                color: var(--dimgray);
                background-color: var(--gray);
                border-color: var(--dimgray);
            }
        }
    }

    li.disabled{
        display: none;
        cursor:not-allowed;
        pointer-events: none;
        border-color: #ccc;
        span{
            display: block;
            padding:.5rem .75rem;
        }    
    }
}