.featureItemSwiper {

    .fcs-item {
        // min-height: 400px;
        @include media($large-screen) {
            display: flex;
            flex-direction: row-reverse;
            > div { width: 50%; }
        }

        .fcs-image {
            position: relative;
            height: 0;
            overflow: hidden;
            padding-top: 80%;
            background-color: var(--dimgray);
            @include media($large-screen) {
                padding-top: 30%;
                height: auto;
            }
            img {
                @include absPos(0,0,auto,0);
                margin: auto;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .fcs-content {
            padding: 2em;
            @include media($large-screen) { padding: 3em; }
            @include media($small-desktop) { padding: 3em 4em; }
        }
        
        .fcsc-wrap {
            @include media($large-screen) {
                display: flex;
                flex-wrap: wrap;
                height: 100%;
                > div {
                    display: flex;
                    width: 100%;
                }

                .fcs-caption { 
                    align-items: center; 
                    h1{
                        text-transform: uppercase;
                        color: var(--primary);
                        font-weight: 800;
                        font-size: 30px;
                    }
                }
                .fcs-cta { align-items: flex-end; }
            }

            .h2 {
                font-size: 30px;
                margin: 20px 0;
                @include media($desktop) {
                    font-size: 50px;
                    max-width: 80%;
                }
            }
        }
    }
}