@import 'swiper/swiper-bundle.css';
@import 'video-js/video';
@import 'selectric/src/selectric.scss';

// NB: container styles + lazyload style is in main.scss

// Overrides
.swiper-button-next, .swiper-button-prev {
    &:focus { outline: none; }
    &:after {
        font-size: 20px;
        font-weight: 900;
        color: var(--primary-1);
    }
}

.selectric-wrapper {
    & + .error {
        color: red;
        margin-top: 5px;
        font-weight: 400;
        font-size: 80%;
        font-style: italic;
    }
    .selectric {
        background-color: #fff;
        border-color: #FFF;
        .button {
            background-color: transparent;
            color: #FFF;
            bottom: 0; margin: auto;
        }
        .label {
            line-height: 1.2;
            height: auto;
            font-size: 14px;

            padding-top: 8px;
            padding-bottom: 8px;
            margin-left: 10px;
            @include media($tablet) {
                padding-top: 10px;
                padding-bottom: 10px;
                margin-left: 15px;
            }
        }
    }
    &.selectric-focus {
        box-shadow: 0 0 2px 1px rgba(var(--primary-1-rgb),.25);
    }
    &.selectric-big .selectric .label {
        @include media($tablet) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    &.selectric-sharp .selectric { border-radius: 0; }
}

.video-js {
    &.vjs-paused .vjs-big-play-button { display: block; }
    .vjs-big-play-button {
        @include absAll(0);
        margin: auto;
        border: 0;
        width: 100%;
        height: auto;
        background-color: rgba(0,0,0,0.5);
        border-radius: 0;
    }
    &.video-vimeo {
        .vimeoFrame { position: static !important; }
        .vjs-control-bar { display: none; }
    }

    .vjs-big-play-button .vjs-icon-placeholder {
        position: relative;
        display: block;
        border: 5px solid #fff;
        border-radius: 50%;
        line-height: 1;
        margin: auto;
        height: 90px;
        width: 90px;

        &:before {
            display: block;
            font-size: 80px;
            @include absAll(0);
            margin: auto;
        }
    }

    .vjs-big-play-button .vjs-icon-placeholder + .vjs-control-text { margin-top: 20px; }

    .vjs-big-play-button .vjs-control-text {
        position: static;
        display: block;
        height: auto;
        width: auto;
        font-size: 17px;
    }
}