.accordion {
    &.accordion-default {
        .accordion-heading {
            background-color: var(--secondary);
            padding: 20px 50px;
            h3 { margin: 0; }
        }
        .accordion-item {
            border-bottom: 1px solid var(--primary);
            .header {
                position: relative;
                cursor: pointer;
                padding: 20px 40px 5px 0px;
                /*@include media($medium-screen) { padding: 20px 20px 20px 60px; }
                @include media($large-screen) { padding: 30px 40px 30px 80px; }
                @include media($small-desktop) { padding: 40px 60px 40px 100px; }*/
                h3 {
                    margin: 0;
                    color: var(--primary);
                    font-size: 20px;
                    @include media($medium-screen) { font-size: 23px; }
                    @include media($large-screen) { font-size: 25px; }
                }

                .bullet {
                    margin: auto;
                    display: block;
                    height: 17px;
                    width: 17px;
                    @include absPos(0, 10px, 0, auto);
                    @include media($medium-screen) { right: 5px; }
                    @include media($small-desktop) {
                        right: 5px;
                        height: 20px;
                        width: 20px;
                    }
                    &:before, &:after {
                        display: block;
                        content: '';
                        background-color: var(--secondary);
                        height: 100%;
                        width: 3px;
                        @include media($small-desktop) { width: 4px; }
                        overflow: hidden;
                        border-radius: 3px;
                        @include absAll(0);
                        margin: auto;
                    }
                    &:after { transform: rotate(90deg); }
                }
            }

            &.open .header .bullet { 
                &:before { display: none; }
                &:after { background-color: var(--primary-1); }
            }

            .content {
                display: none;
                padding: 10px 10px 20px 40px;
                @include media($medium-screen) { padding: 10px 20px 20px 60px; }
                @include media($large-screen) { padding: 10px 40px 30px 80px; }
                @include media($small-desktop) { padding: 0 60px 40px 100px; }
                a { color: var(--secondary); }
            }
        }
    }
}