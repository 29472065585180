.t-case-study{
    &.t-shared-item{
        .hero .hero-content .subheading{
            font-weight: 800;
            font-size: clamp(14px, 3.5vw, 34px);
            max-width: 900px;
            
        }
        .container{
            padding:0 50px;
            @include mediaDown($large-screen) {
                padding:0 20px;
            }
            @include mediaDown($mobile) {
                padding:0 10px;
            }
        }
        .section-module.hero{
            padding:0 2rem;
            .hero-content{
                min-height: max(450px, 25vw);
            }            

            .content-wrapper{

                padding:0;
                max-width: 1200px;
                text-align: left!important;
            }
        }
        .headings{
            width: 100%!important;
            color: #0B2D52;
            .page-title{
                font-weight: 800;
            }
            .meta{
                color: #F77474;
            }
        }
        .two-col{
            display: grid;
            grid-template-columns: 1fr 300px;
            padding:0;
            gap:1rem;
            @include mediaDown($large-screen) {
                grid-template-columns: 1fr;
            }
        }
        .container-narrow{
            padding:0;
        }
        .sc-wrap{
            
            justify-content: center;
            margin:0;
        }
        .fence-solution {
            background-color: var(--secondary);
            a {
                color: #fff;
            }
        }
        .fence{ 
            display: flex;
            align-items: stretch;
        
            .fence-image {
                width:75%;
                min-height: 100%!important;
                object-fit: cover;
            }
            .fence-content {
                padding:2em;
                .fence-title {
                    font-weight: 800;
                    color:white;
                }
                .wrap{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    gap:2rem;
                }
            }
            @include mediaDown($small-desktop){
             
                .fence-image {
                    width:50%;
                }
            }
            @include mediaDown($medium-screen){
                flex-wrap: wrap;
                .fence-image {
                    width:100%;
                }
            }
        }
        .related-blocks{
            .container{
                max-width: 1300px;
            }

            .contentImage{
                .bgImage{
                    border-radius: 50px;
                    aspect-ratio: 1 / 1;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    max-width: 480px;
                }
                .ci-content{
                    @include mediaDown($large-screen){
                        padding-bottom: 0;
                    }
                    
                }
            }
            .contentImage:nth-child(5n+1) .ci-image:after{
                background-color: #0086B0;
            }
            .contentImage:nth-child(5n+2) .ci-image:after{
                background-color: #F77474;
            }
            .contentImage:nth-child(5n+3) .ci-image:after{
                background-color: #0D4C5D;
            }
            .contentImage:nth-child(5n+4) .ci-image:after{
                background-color: #4D6066;
            }
            .contentImage:nth-child(5n+5) .ci-image:after{
                background-color: #F55C1E;
            }
        }
        .section-module{
            // padding-bottom: clamp(0px, 3vw, 5em);
            padding: 8em 0;
        }
    }
}
.read-more{
    margin-top: 2rem;
    display: flex;
    gap:2rem;
}