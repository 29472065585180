.entryListing {
    .heading { margin-bottom: 50px; }
    .el-rw {
        @include row(20px);
        justify-content: center;
        .el-item {
            margin-bottom: 40px;
            width: 100%;
            @include media($medium-screen) { width: 50%; }
            @include media($large-screen) {
                width: 33.333333%;
                &.quarters { width: 25%; }
                &.thirds { width: 33.333333%; }
                &.half { width: 50%; }
                &.full { width: 100%; }
            }
        }
        .postThumb { margin-bottom: 0; }
    }

    &.fenceTypes .el-rw {
        @include row(30px);
    }
}