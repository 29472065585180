.usps .usps-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding: 2em 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;

    li {
        display: block;
        padding: 10px 5px;
        font-weight: 600;
        font-size: 1rem;
        box-sizing: border-box;
        img,
        svg {
            width: 100px;
	        position: relative;
	        margin-bottom: 8px;
	        height: 100px!important;
            object-fit: contain;
        }
        svg {
            fill: var(--primary-1);
        }
    }
}