
$quick: 200ms; /* buttons, svgs & text transitions */
$medium: 1s; /* banner swap transitions */
$radius: 12px; /* buttons and item lists */

:root {
    //color
	// MH: Why are these being set to rgb values? Why not hex? I presume a mixin needs RGB?
    --primary: rgb(11,45,82); // navy blue #0B2D52
    --primary-1: rgb(0,134,176); // blue #0086B0
    --primary-1-rgb: 0,134,176; // for use in rgba function
    --secondary: rgb(245,92,30); //orange #F55C1E
    --secondary-2: rgb(247, 116, 116); // pink #F77474
    --tertiary: rgb(13, 76, 93); // turquoise #0D4C5D

    --gray: rgb(238,241,245); //#EEF1F5
    --gray-1: rgb(230,237,238); //#E6EDEE
    --gray-2: #999999;
    --dimgray: #4d6066;

    //font style
    --font-primary: 'Roboto', sans-serif; // main
    --font-secondary: 'Public Sans', sans-serif; // headings
	--button-radius: #{$radius};
}

@mixin overlay($col: var(--dimgray), $op: 0.4, $mode: multiply) {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: $col;
    opacity: $op;
    mix-blend-mode: $mode;
    z-index: 1;
}

/* Old theme styles */
$mobile: 480px;
$medium-screen: 600px;
$tablet: 768px;
$large-screen: 992px;
$small-desktop: 1200px;
$desktop: 1500px;
$wide-screen: 1780px;


/** txt colours **/
.text-black {
    color: #000;
}
.text-white { /** todo: restrict so this only works when visible */
	color: #fff;
}
/** backgrounds **/
.bg-turquoise{
	background-color: var(--tertiary);
	.pink_underline {
        &:hover {
            color: #fff;
            border-color: #fff;
        }
    }
}
.bg-blue,
.bg-deepblue,
.bg-orange {
	color: #FFF;
	h1, h2, h3, h4, h5, h6 {
		color: inherit;
	}
	.pink_underline {
	    color: var(--secondary-2);
	    border-color: var(--secondary-2);
	}
	form {
		color: #000;
	}
}
.bg-white { background-color: #fff; }
.bg-blue {
    background-color: var(--primary-1);
    // can't see blue on blue
    .button {
        background-color: #FFF;
    }
}
.bg-deepblue {
    background-color: var(--primary);
    // can't see navy on navy
    .button {
        &:hover {
            background-color: var(--secondary);
        }
    }
    a,
    .pink_underline {
        &:hover {
            color: #fff;
            border-color: #fff;
        }
    }
}
// .orange_underline{

// }
.bg-orange { background-color: var(--secondary); }
.bg-gray { background-color: var(--gray); }
.bg-dimgray { background-color: var(--dimgray); }

// A link hover transition
@mixin aHover() {
	transition: all $quick ease;
	&:hover { @content; }
}
/** add functions for ones we want to reuse in editor & forms */
@mixin button_shared(){
    text-decoration: none;
	display: inline-block;
	font-weight: bold;
	font-family: var(--font-secondary);
	font-size: 1rem !important;
	line-height: 1.1;
    padding: 10px 20px 8px 20px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    border-radius: var(--button-radius);
    transition: color $quick ease, background-color $quick ease;
    cursor: pointer;
	vertical-align: middle;
}
/*
@mixin btn_round(){
	border-radius: 100px;
}*/
@mixin btn_primary_rev(){
	background-color: var(--primary-1);
    border-color: var(--primary-1);
    color: #fff;
    @include aHover() {
        color: #fff !important;
        background-color: var(--primary);
        border-color: var(--primary);
    }
}
@mixin orange_button(){
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: #fff;
    @include aHover() { color: #fff; background-color: var(--secondary-2); }
}
.btn {
    @include button_shared;
    &.btn-bold { font-weight: 800; }
    /*&.btn-round {
        @include btn_round;
    }*/
    &.btn-sharp { border-radius: 0; }
    &.btn-primary {
        background-color: var(--primary);
        border-color: var(--primary);
        color: #fff;
        @include aHover() {
            color: #fff;
            background-color: var(--primary-1);
            border-color: var(--primary-1);
        }
    }
    &.btn-primary-reverse {
		@include btn_primary_rev;
    }
    &.btn-secondary {
        @include orange-button;
    }
    &.btn-default {
        background-color: #fff;
        color: var(--primary-1);
        @include aHover() {
            background-color: var(--primary-1);
            color: #fff;
        }
    }
}

// Media Queries
@mixin media( $width ) {
	@media ( min-width: $width ) {
		@content;
	}
}

@mixin mediaDown( $width ) {
	@media ( max-width: $width - 1 ) {
		@content;
	}
}

@mixin mediaMinMax( $minWidth, $maxWidth ) {
	@media ( min-width: $minWidth ) and ( max-width: $maxWidth - 1 ) {
		@content;
	}
}

// grid - row
@mixin row($gap: null, $child: null, $position: null) {
	display: flex;
	flex-wrap: wrap;

	@if ($position) {
		position: $position;
	}

	@if ($gap) {
		margin-left: -$gap;
		margin-right: -$gap;
	}

	// Normally columns are div element
	> * {
		@if ($position) {
			position: $position;
		}
		// if $display is not set to grid
		@if ($child) {
			display: $child;
		} @else {
			display: block;
		}


		width: 100%;
		@if ($gap) {
			padding: 0 $gap;
		}
	}
}
// grid - column
@mixin column($width: null, $display: null) {
	// if $child is not set to row
	@if ($display) {
		display: $display;
	}

	@if ($width) {
		width: $width;
	} @else {
		width: 100%;
	}

	@content;

}

@mixin uppercase {
    text-transform: uppercase;
}

@mixin cover-background($background-url) {
    background-image: url($background-url);
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

@mixin clearfix {
    &::after {
        clear: both;
        content: "";
        display: block;
    }
}

// Placeholder
@mixin placeholder() {
	&::-webkit-input-placeholder { @content }
	&::-moz-placeholder { @content }
	&:-ms-input-placeholder { @content }
	&:-moz-placeholder { @content }
}

@mixin absPos($top: null, $right: null, $bottom: null, $left: null) {
	position: absolute;
	@if ($top) { top: $top; }
	@if ($right) { right: $right; }
	@if ($bottom) { bottom: $bottom; }
	@if ($left) { left: $left; }
}
@mixin absAll( $pos ) {
	position: absolute;
	top: $pos;
	right: $pos;
	bottom: $pos;
	left: $pos;
}

// Background image - expects an image to a bg attribute of div
@mixin bgImg( $color: null ) {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@if ( $color ) {
		background-color: $color;
	}
}
// Background image - expects an image to a <img> tag inside a div
@mixin imgBg() {
	@include absAll(0);
	width: 100%;
	height: 100%;
	margin: auto;
	object-fit: cover;
	object-position: center;
	@content;
}

@mixin masonry($col, $gap) {
	column-count: $col;
	column-gap: $gap;

	> div {
		display: inline-block;
		width: 100%;
		margin-bottom: $gap;

		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}
}

@mixin container( $paddingX: null ) {
    margin: auto;
	width: 100%;
	padding: 0 if($paddingX, $paddingX, 20px);
}

/*
@mixin fcOverlay( $color ) {
    box-shadow: inset 120px -120px 120px 20px $color;
    @include media( $tablet ) {
        box-shadow: inset 120px -150px 100px 50px $color;
    }
}*/

@mixin fcOverlay( $color ) {
    box-shadow: inset 0px -30px 20px -10px $color;
}

/** from utilities in old theme **/
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-upper { text-transform: uppercase; }
.text-normal { text-transform: none; }

.no-list {
	padding: 0;
	margin: 0;
	list-style-type: none;
	li::before {
		display: none;
	}
}
.ul-inline-block > li { display: inline-block; }

.align-items-middle { align-items: center; }
.flex-middle {
	display: flex;
	@extend .align-items-middle;
}

.justify-content-center { justify-content: center; }
.flex-center {
	display: flex;
	@extend .justify-content-center;
}

.strike {
	text-decoration: line-through;
}

.well {
	min-height: 20px;
	padding: 19px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

.italic { font-style: italic; }

.font-stack-1 {
    font-family: var(--font-primary);
}
.font-stack-2 {
    font-family: var(--font-secondary);
}

.bgImage {
	@include absAll(0);
	@include bgImg();
	z-index: 0;
}

.overflow-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

