.faded-card {
    color: #000;
    a {
        display: inline-block;
        margin-top: 15px;
        color: #000;
    }
    &:not(.bg-gray) {
        .number, .title { color: #fff; }
        .description, a { color: rgba(#fff,0.7); }
    }

    /* hover effect if video */
    // @include aHover() {
    //     &.bg-blue, &.bg-deepblue {
    //         .info .title { color: $secondary; }
    //     }
    //     &.bg-gray, &.bg-orange {
    //         .info .title { color: $primary; }
    //     }
    //     .image .overlay span { opacity: 1 !important; }
    // }

    &.bg-gray .image .overlay {
        @include fcOverlay( var(--gray) );
    }
    &.bg-orange .image .overlay {
        @include fcOverlay( var(--secondary) );
    }
    &.bg-blue .image .overlay {
        @include fcOverlay( var(--primary-1) );
    }
    &.bg-deepblue .image .overlay {
        @include fcOverlay( var(--primary) );
    }

    .image {
        position: relative;
        overflow: hidden;
        height: 0;
        padding-top: 75%;
        img { @include imgBg(); }
        div.bgImage { height: calc(100% - 1px); }
    
        .overlay {
            @include absAll(0);
            padding: 20px;
            @include media($small-desktop) { padding: 30px; }
            @include media($desktop) { padding: 40px; }
            padding-bottom: 0 !important;
            display: flex;
            align-items: flex-end;
        }
    
        .overlay .play {
            display: flex;
            align-items: center;
        }
        .overlay .play svg {
            width: 50px;
            margin-right: 15px;
            @include media($small-desktop) { width: 75px; }
        }
        .overlay .play span {
            font-size: 17px;
            opacity: 0;
            transition: opacity 300ms ease;
            @include media($small-desktop) { font-size: 19px; }
        }

        & + .info { padding-top: 0; } 
    }

    .info {
        padding: 30px;
        @include media($small-desktop) { padding: 50px; }
        @include media($desktop) { padding: 60px; }
        h2.number {
            font-size: 45px;
            @include media($small-desktop) { font-size: 60px; }
            @include media($desktop) { font-size: 65px; }
        }
        h3.title {
            font-size: 25px;
            @include media($small-desktop) { font-size: 35px; }
            @include media($desktop) { font-size: 40px; }
        }
    }
    
}