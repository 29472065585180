/** menu generic **/
nav  {
	ul {
		list-style:none;
	    padding: 0;
	    margin: 0;
	}
	li {
	    display: inline-block;
	    box-sizing: border-box;
	    /* override editor ul style */
	    position: static;
	    padding-left: 0px;
	    line-height: 1;
	    &::before {
	        display: none;
	    }
	    /* subs */
	    ul {
	        display: none;
	    }
	    li {
	        display: block;
	    }
	}
	a {
	    display: block;
	    color:inherit;
	    text-decoration: none;
	}
/** slide menu override **/
	&.slide-menu {
		li {
			display: block;
		}
	}
}



