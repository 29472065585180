.infopackDownload {

    position: relative;
    z-index: 1;

    .r {
        color: #fff;
    }

    .ef-box {
        padding: 3em;
        @include media($desktop) { padding: 5em 3em; }
    }

    .ef-rw{
        @include row(30px);
        align-items: center;
        @include media($large-screen) {
            .ef-image { width: 40%; }
            .ef-content { width: 60% }
        }
    }

    .ef-image {
        position: relative;
        margin-top: -120px;
        margin-bottom: 30px;
    }
    .content-wrap.content-main {
        margin-bottom: 30px;
        .heading {
            color: #fff;
            font-size: 40px !important;
        }
        p.subheading {
            font-size: 17px;
            margin-bottom: 30px;
        }
        @include media($large-screen) {
            ul {
                column-count: 2;
                max-width: 75%;
            }
        }
        ul li:before {
            background-color: #fff;
            border-radius: 50%;
            width: 5px;
            height: 5px;
        }
    }

    .ebookForm {
        .freeform-row.form-row {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -5px;
        }
        .freeform-column.form-col {
            margin: 5px 0;
            padding: 0 5px;
            width: 100%;
            flex: unset;
            @include media($large-screen) {
                &:nth-child(1), &:nth-child(2) { width: 50%; }
            }
            @include media($small-desktop) { width: 33.333333% !important; }
        }

        input, button, .selectric .label {
            border: 0;
            border-radius: 0;
            display: block;
            width: 100%;
            height: 100%;
            padding: 15px 20px 14px 20px;
            @include media($large-screen) { padding: 18px 20px 17px 20px; }
        }

        .selectric {
            border: 0;
            border-radius: 0;
            .label { margin: 0; }
        }

        button {
            background-color: var(--primary);
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}
