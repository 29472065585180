//NB: uses styles in vendor/swiper as well.
.testimonialSlider {
    .quote {
        position: relative;
        text-align: center;
        span {
            display: inline-block;
            font-size: 0px;
            width: 24px;
            height: 20px;
            background-image: url("../assets/quote-open.svg");
            background-size: contain;
            background-position: center;
        }
        &.quote-open { margin-bottom: 1em; }
    }

    .ts-item {
        padding: 1em;
        margin: auto;
        @include media($large-screen) { max-width: 70%; }
        .ts-content {
            line-height: 1.25;
            font-size: 24px;
            margin-bottom: 2em;
        }
        .ts-meta {
            color: var(--primary-1);
            font-size: 0.8rem;
            > * {
                margin-top: 0px;
                margin-bottom: 10px;
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .testimonialSlider {
        .ts-item {
            .ts-content {
                font-size: 18px;
            }
        }
    }
}