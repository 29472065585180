.headingsBannerImage {
    .hbi-contents .heading-row {
        display: flex;
        flex-wrap: wrap;

        h2 {
            color: var(--primary-1);
        }
        .description {
            font-size: 17px;
        }

        @include media($large-screen) {
            align-items: center;
            flex-wrap: nowrap;
            > h2, > div { width: 100%; }
        }
    }

    .hbi-contents + .hbi-image {
        margin-top: 5em;
        position: relative;
        z-index: 1;

        img { width: 100%; }
    }
}