.iconContentColumns {

    .icc-rw {
        @include row(6em);
        text-align: center;
        @include media($large-screen) {
            flex-wrap: nowrap;
            text-align: inherit;
            .icc-col:not(:first-child) { border-left: 1px solid var(--gray-1); }
        }

        .icc-col {
            padding-top: 1em;
            padding-bottom: 1em;

            @include media($large-screen) { font-size: 17px; }
        }

    }

    .icci-icon {
        display: block;
        width: 90px;
        margin: 0 auto 50px auto;
        @include media($large-screen) { margin: 0 0 50px 0; }
    }

}