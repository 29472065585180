#banner_2024{
    position: relative;
    width: 100%;
    min-height: calc(100vh - 180px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    #warranty_shed{
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
    #warranty_dogs{
        position: absolute;
        z-index:2;
        width:  clamp(300px, 76vw, 1000px);
        max-width: 70vh;
        margin-top: min(10vw, 20vh);
        
        -webkit-animation: scale-in-center 0.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: scale-in-center 0.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        
    }
    #warranty_text{
        position: absolute;
        z-index: 2;
      
        top: -10px;
        width: clamp(300px, 90vw, 1000px);
        max-width: 65vh;
        -webkit-animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
	        animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-12-12 20:13:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  
  /* ----------------------------------------------
 * Generated by Animista on 2024-12-12 20:17:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  