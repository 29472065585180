/*** =============== shared index (list) =============== ***/
span,
p {
	&.meta {
		display: block;
	    margin-bottom: 20px;
	    color: var(--primary-1);
	    &:not(:first-child) {
	        margin-top: 10px;
	    }
	    &.categories {
            text-transform: uppercase;
		    font-size: 85%;
		    font-family: var(--font-secondary);
	    }
	}
}
.list {
	@include row(20px);
	padding-top: 40px;

    .item {
        display: inline-flex; /* rows equal height */
		margin-bottom: 40px;
        @include media($medium-screen) { width: 50%; }
        @include media($large-screen) { width: 33.333333%; }
        a {
            text-decoration: none;
        }
        .meta-section {
            color: var(--primary-1);
        }        
    }
    .postThumb,
    .cat-thumb { /* link wrap */
		display: flex; /* fill row height */
		flex-direction: column;
		width: 100%;
		box-sizing: border-box;
		.image {
			flex-grow: 0; /* height set */
		    display: block;
		    width: 100%;
		    position: relative;
		    overflow: hidden;
		    padding-top: 70%;
		    background-color: var(--dimgray);
		    border-top-left-radius: $radius;
		    border-top-right-radius: $radius;
		    box-sizing: border-box;
			// fallback style is in main (used here and in featureItemSwiper)
        }
        .info {
            flex-grow: 1; /* fill height */
            display: block;
            text-align: center;
		    width: 100%;
		    padding: 10px 20px;
		    background-color: var(--gray);
		    border-bottom-left-radius: $radius;
		    border-bottom-right-radius: $radius;
		    outline: 1px solid var(--gray-1);
		    outline-top: 0px;
            .category {
	            display: block;
	            margin-bottom: 15px;
	            color: #000;
	            @include aHover() { color: rgba(#fff, 0.5); }
	        }
            .meta-date {
				display: block;
			    margin-bottom: 20px;
			    color: var(--primary-1);
            }
	        .title {
	            display: block;
	            margin-bottom: 0;
	            font-family: var(--font-secondary);
	            font-weight: 700;
	            color: var(--primary);
	            font-size: 18px;
	        }
        }
		@include aHover {
	        .image img { transform: scale(1.25); }
	        .info {
	            background-color: var(--primary) !important;
	            .title, .category, .meta-section { color: #fff; }
	            .meta-date { color: rgba(#fff, 0.75); }
	        }
	    }
    }
}
/** filtering styles **/
.filtering {
    background-color: var(--gray);
    padding: 20px;
    margin-top: 10px;

    .f-wrap {
        margin: auto;
        @include media($small-desktop) { max-width: 80%; }
    }
	.f-rw {
        @include row(20px);
        justify-content: center;
        > .f-field {
            display: block;
            @include media($large-screen) {
                width: 33.333333%;
                .form-group { margin-bottom: 0; }
            }
        }
    }
    .f-field {
        display: block;
        @include media($large-screen) {
            width: 33.333333%;
            .form-group { margin-bottom: 0; }
        }
    }

	.selectric,
    .f-search {
        border-radius: 30px; // match selectric
		border-color: #FFF;
    }
}
.t-shared-index {
	.section-module.list-wrap {
		padding-top: 0px;
		&.t-testimonials,
		&.t-faqs {
			padding-top: 5em;
		}
	}
}

/*** ======= testimonials ======== ***/
.t-testimonials {
	.testimonial {
		padding-top: 30px;
		border-bottom: 1px solid #eee;
	}
}

/*** =============== shared item =============== ***/
.section-contents {
    padding-top: 4em;

    .sc-wrap {
        @include row(20px);
		> * {
			display: block;
			padding: 0px 20px;
		}
        &.top-heading .headings {
            @include media($large-screen) {
                width: 88%;
                margin-left: auto;
            }
            .meta {
                display: block;
                &:not(:first-child) { margin-top: 10px; }
            }
            .meta.categories {
                text-transform: uppercase;
                font-size: 85%;
                font-family: var(--font-secondary);
            }
        }

        @include media($large-screen) {
            .social {
                width: 12%;
                .social-sharer {
                    text-align: center;
                    .label { margin-bottom: 15px; }
                    li { width: 100%; }
                    li a { margin: auto; }
                }
            }
        }

        @include media($small-desktop) { margin-left: -12%; }

        .content-main {
            @include media($large-screen) {
                width: 88%;
                figure {
                    margin-left: -5%;
                    margin-right: -5%;
                }
            }

            figure {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                align-items: flex-end;
            }
            .fig-img {
                .img-item {
                    display: block;
                    padding: 0 5px;
                    margin-top: unset;
                    margin-bottom: 10px;
                    width: 100%;
                }
                &.multi-img img { width: 100%; }
            }

            figure.fig-img.quarters .img-item {
                @include media($large-screen) { width: 25%; }
            }
            @include media($medium-screen) {
                figure.fig-img.thirds .img-item { width: 33.333333%; }
                figure.fig-img.half .img-item, figure.fig-img.quarters .img-item { width: 50%; }
            }
        }
    }

    .headings {
        margin-bottom: 50px;

        .h2 {
            font-size: 36px;
            line-height: 1.4;
            @include media($large-screen) {
                font-size: 40px;
                margin-bottom: 35px;
            }
        }
        .meta { color: var(--primary-1); }

    }
}
.social-sharer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -5px 20px -5px;

    > li {
        display: block;
        padding: 0 5px;
    }

    .label {
        text-transform: uppercase;
        font-family: var(--font-secondary);
        font-weight: 600;
    }

    .ss {
        margin: 5px 0;
        a {
            display: block;
            position: relative;
            width: 40px;
            height: 40px;
            background-color: var(--primary-1);
            border-radius: 5px;
            color: #fff;
            @include aHover() { background-color: var(--secondary); }
        }

        img {
            @include absAll(0);
            margin: auto;
            height: 18px !important;
            width: 18px;
        }
    }
}

/*** =============== search =============== ***/
.page-search {

    .search-header {
        position: relative;

        .overlay {
            @include absAll(0);
            margin: auto;
            overflow: hidden;

            &:after {
                @include absAll(0);
                content: '';
                background-color: rgba(var(--primary-1-rgb),0.5);
            }

            img {
                @include imgBg();
            }

        }
        .container-narrow { position: relative; }

        .search-group {
            position: relative;
            display: block;
            margin: auto;

            @include media($tablet) { max-width: 75%; }

            .search-field {
                border: 0;
                padding: 15px 50px 15px 20px;
                border-radius: 50px;
            }

        }

        .search-group button {
            width: 18px;
            svg {
                width: 18px;
            }
        }
    }
}


/*** =============== fences =============== ***/

.page-fences {
    .h2.text-upper {
        text-transform: none;

        .r {
            color: #fff;
        }
    }
    .ff-listing {
        .el-rw { margin-bottom: 40px; }
        .cta-wrap .cta { min-width: 180px; }
    }
    .content-main {
        @include orange-square-list;
    }
    .f-details {
        .heading {
            margin-bottom: 50px;
        }

        .pd-rw {
            @include row(20px);
            justify-content: center;
            .pd-col { @include media($large-screen) { width: 33.333333%; } }
        }

        .pd-item {

            span { display: block; }
            .info .sizing {
                font-family: var(--font-secondary);
                text-transform: uppercase;
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 10px;
            }
            .info .desc {
                margin-bottom: 20px;
            }

            .img-fence {
                display: block;
                margin: 30px auto;
                max-width: 90%;
            }

            .specs li {
                margin: 10px;
                font-weight: 700;
                img { margin-bottom: 15px; }
            }

        }
    }
}
.product_features {
	/** auto tick-columns */
	ul {
		padding: 0;
	    list-style-type: none;
	    display: flex;
		flex-wrap: wrap;
		justify-content: center;
		justify-content: space-evenly;
		padding: 0px;
		width: 100%;
		box-sizing: border-box;
		li {
			position: relative;
			display: inline-block;
			width: 50%;
			padding: 30px 5%;
			min-height: 100px; /* for tick */
			padding-left: 150px; /* for tick */
			border-left: 0px;
			// mimic h3
	        font-family: var(--font-secondary);
	        font-weight: 600;
	        font-size: 20px;
	        line-height: 1;
	        text-transform: uppercase;
			&::before {
				/* tick */
				content:"";
				display: block;
				position: absolute;
				top: 10px;
				left: 0px;
				width: 80px;
				height: 80px;
				background-color: transparent;
				background-image: url("../assets/green-tick.svg");
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
	}
}
@media only screen and (max-width: 960px){
	.product_features {
		ul {
			li {
				width: 80%;
			}
		}
	}
}

/*** =============== offices index (contact page) =============== ***/
/** office list items **/
.shopInformation {

    .si-rw {
        @include row(20px);
        align-items: center;
    }
    .si-cl {
        margin-bottom: 40px;
        @include media($medium-screen) { width: 50%; }
        @include media($small-desktop) { padding: 0 35px; }
    }

    .info-item {
        color: inherit;
        display: block;
        text-align: center;
        @include aHover {
            .title { color: var(--primary-1); }
        }
        > img {
            display: block;
            margin: 0 auto 50px auto;
            width: 65px;
        }

        .title {
            margin-bottom: 50px;
            font-size: 30px;
            @include media($small-desktop) { font-size: 40px; }
        }

        .phone { margin-bottom: 15px; }
        span {
            font-weight: 600;
            @include media($small-desktop) { font-size: 22px; }
            @include media($desktop) { font-size: 20px; }
        }

        .address + .image { margin-top: 50px; }
        .image {
            position: relative;
            overflow: hidden;
            padding-top: 60%;
            box-sizing: border-box;
        }

        .image img {
            @include imgBg();
        }
    }
}

/*** office item template ***/
.mapContent {

    h2.heading {
        margin-bottom: 30px;
    }
	#map {
		position: relative;
		min-height: 450px;
	}
    iframe {
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
    }

    .mc-box .mcb-rw {
        @include row(20px);
        align-items: center;
        @include media($large-screen) {
            .mcb-cl.mcbc-map { width: 70%; }
            .mcb-cl.mcbc-content { width: 30%; }
        }

    }

    .mcbc-content .mcbc-cwrap {
        padding: 3em;
    }
}