.feature-list {
    &.news {
        background-color: var(--gray);
        .item {
            .info {
                background-color: #FFF;
            }
        }
    }
    .flex-heading {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        > * {
            margin: auto 10px;
        }
    }
    .heading {
        color: var(--primary-1);
    }
    .list {
        margin-bottom: 40px;
        @include row(20px);
        justify-content: center;

        .item {
            margin-bottom: 40px;
            @include media($medium-screen) { width: 50%; }
            @include media($small-desktop) { width: 25%; }
        }

        .postThumb {
            margin-bottom: 0;
            .info .category {
                display: none;
            }
        }
    }

}