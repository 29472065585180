.t-product{
    .page-title{
        font-weight: 800;
    }
   .subheading{
        max-width: 1200px;
        margin:0 auto;

   }
   .product-content{
        display: flex;
        align-items: start;
        padding-top:clamp(2rem, 10vw, 8rem);
        & > * {
            margin: 2rem 0;
        }
        .image-section{
            flex-shrink: 0;
            margin-right:2em;
            picture{
                display: block;
                position: relative;
                border-radius: 50px;
                img{
                    border-radius: 50px;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    max-width: 480px;
                    
                }
                &::after{
                    content:'';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    height: calc(100% + 10%);
                    margin: auto;
                    width: 20%;
                    background-color: var(--primary-1);
                    z-index: 0;
                    right: auto;
                    left: -10%;
                    z-index: -1;
                }
            }
            
            
        }
        .content-section {
            flex-grow: 1;
        }
        @include mediaDown($tablet) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            & > * {
                width: 100%;
            }
        }

    }
    .product-features{
        display: flex;
        flex-wrap: wrap;
        gap:2rem;
        justify-content: center;
        padding:2rem 20px;
        .card{
            position: relative;
            border: 1px solid #ccc;
            border-radius: 20px;
            overflow: hidden;
            img{
                width: 100%;
                max-width: 230px;
                height: 230px!important;
                object-fit: cover;
                aspect-ratio: 1;
            }
            .info{
                position: absolute;
                bottom:0;
                left:0;
                width: 100%;
                font-weight: 700;
                background-color: #EEF1F5;
                color: var(--primary);
                padding:10px;
            }
        }
    }
    .product-specifications {
        background-color: #eef1f5;
        padding-top:5em;
        .flex.spec-title {
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            h3 {
                margin:0;
                margin-right:2em;                
            }
            form {
                width: auto;
            }
            margin-bottom: 1em;
        }
        h2 {
            color: var(--primary-1);
        }
        h3 {
            color: var(--primary);
        }
        .selectric {
            background-color: var(--primary-1);
            .label {
                color: white;                
            }
            .button:after {
                border-top-color: white;
            }
        }
    }
    .product_gallery{
        background-color: #eef1f5;
        a{
            aspect-ratio: 16/9;
            object-fit: cover;
            width: 100%;
            height: 100%;
            margin:0;
        }
    }
    #why-go-a-bluedog-fence {
        background-color: white;
    }
    .related-blocks {
        .contentImage{
            padding: 0;
            .heading{
                color: var(--primary-1);
            }
            // .flex .ci-content{
            //     padding-top: 0;
            //     padding-: 0;
            // }
            .ci-image:after{
                display: none;
            }
            
            .ci-image .bgImage picture.bg{
                background-color: transparent;
                img{
                    object-fit: contain;
                    border-radius: 20px;
                }
            }
            .summary {
                font-size: 16px;
                color: var(--primary);
                font-family: var(--font-secondary);
                margin-bottom: 1em;
            }
        }
    }
    .section-module.enquiry{
        padding-top: 3rem;
        background: white;
        #form-result{
            h2{
                padding: 3rem 0;
                color: white;
                text-align: center;
            }
        }
    }

}
#form-result{
    h2{
        padding: 3rem 0;
        color: var(--primary-1);
        text-align: center;
    }
}
.info-pack-modal{
    z-index: 1;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
 
    display: none;

    background-color: rgba(0,0,0,.5);
    .hidden{
        clip: rect(0 0 0 0); 
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap; 
        width: 1px;
    }
    .section-module{
        justify-content: center;
        align-items: center;
        display: flex;
        height: 100%;
        width: 100%;
        padding: 20px;
        .ef-box{
            border-radius: 10px;
            width: 100%;
            max-width: 565px;
        }
        .heading-slim{
            font-size: 30px!important;
            color:#fff;
        }
        input{
            border-radius: 20px;
        }
        &.infopackDownload .ebookForm{
            .freeform-row .freeform-column.form-col {
                width: 50%!important;
                &.full{
                    width: 100%!important;
                }
            } 
            .spacer-top{
                margin-top: 1rem;
                margin-bottom: 0.5rem;
            
            }
            input{
                padding:15px 20px 14px 20px;
            }
            button, .btn{
                background-color: var(--primary-1);
                border-radius: 20px;
                padding:10px 25px;
                width: auto;
                text-transform: none;
               
            }
            @media screen and (max-width:576px) {
                .freeform-row{
                    flex-direction: column;
                    .freeform-column.form-col {
                        width: 100%!important;
                    }
                }
            }
        } 
        .recaptcha-notice a{
            margin-top: 1rem;
            color: #fff;
        }
    }
}