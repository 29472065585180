/*============ sub page============*/
.content-main {
    h1 { font-size: 35px; }
    h2 {
        font-size: 30px !important;
        color: var(--primary-1);
        text-transform: uppercase;
    }
    h3 { font-size: 25px; }
    h4 { font-size: 20px; }
    h5 { font-size: 15px; }
    h6 { font-size: 12px; }

    figure {
        margin: 20px 0 25px 0;
        a, img, iframe {
            display: block;
            max-width: 100%;
        }
        a, img { margin: auto; }

        figcaption {
            margin-top: 5px;
            font-weight: 600;
            text-align: center;
        }
    }

    .fig-iframe { background-color: #000; }
}

/** hero/page-graphic include **/
@mixin hero_heading {
	color: #FFF;
	@include media($tablet) { font-size: 50px; }
    @include media($large-screen) { font-size: 55px; }
    @include media($small-desktop) { font-size: 70px; }
    margin-bottom: 30px;
}
@mixin hero_subheading {
	font-size: 17px;
    @include media($tablet) { font-size: 20px; }
    line-height: 1.75;
}
#tubular-container {
    position: absolute !important;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

iframe#tubular-player {
  position: absolute;
  max-width: none;
}

.hero {
	&.has-img {
		.content-wrapper {
			max-width: 1020px;
		}
	}
	#video-wrapper {
        position: absolute !important; /* to hero */
        top: -6%; /* video profile off screen */
        left: 0px;
        width: 100%;
        height: 110%;
        z-index: 1;
	}
    .hero-content {
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5em 1em;
        &.has-img::before {
            @include overlay;
        }
        @include media($desktop) {
            padding-top: 6.5em;
            padding-bottom: 6.5em;
        }
        &:not(.hero-small) {
            min-height: 450px;
            @include media($large-screen) { min-height: 600px; }
        }

        &.contact {
            align-items: flex-start;
        }
        &.bottom { align-items: flex-end; }

        .content-wrapper {
            position: relative;
            padding: 30px;
			max-width: 100%;
			box-sizing: border-box;
			margin: 0 auto;
			z-index: 1;
        }

        .h2 {
            @include hero_heading;
        }
        .subheading {
            @include hero_subheading;
        }
        .page-banner-buttons {
            vertical-align: middle;
            @include media($tablet) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            a {
                margin: 1em;
                font-weight: bold;
                display: block;
                &.button-orange {
                    display: inline-block;
                }
                &.text-white:hover{
                    color: var(--secondary);
                }
                
            }
        }
    }

    &.bg-blue, .bg-blue,
    &.bg-deepblue, .bg-deepblue,
    &.bg-dimgray, .bg-dimgray,
    &.bg-orange, .bg-orange  {
        .content-wrapper { color: #fff }
    }
}

/*** mimic the hero in banners ***/
.banners-home {
	position: relative; /* for nav */
	background-color: var(--primary-1);
	.banner-stage {
		position: relative;
		max-width: 100%;
		box-sizing: border-box;
		overflow: hidden;
		/* match hero-content size */
		@include media($desktop) {
            padding-top: 6.5em;
            padding-bottom: 6.5em;
        }
        min-height: max(50vh, 500px);
        @include media($large-screen) { min-height: 800px; }
	}
	.banner-image {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		background-position:bottom;
        background-size:cover;
		/* override link */
		display:block;
		color: #FFF;
		text-decoration: none;
		/* match hero-content */
		display: flex;
	    justify-content: center;
	    align-items: center;
	    padding: 5em 1em;
	    /** padding for the nav **/
        padding-bottom: 8em;
		/* transition */
		opacity: 0;
		z-index: 0;
		transition: opacity $medium ease;
        img{
            object-fit: cover;
            height: 100%!important;
            width: 100%!important;
            position: absolute;
            z-index: -1;
            inset: 0;
        }
    
		&::before {
			@include overlay;
		}
		&.active {
			opacity: 1;
			z-index: 1;
		}
	}
	.banner-content {
		/* match hero: container */
		position: relative;
        margin: auto;
	    width: 100%;
	    padding: 0 20px;
	    max-width: 1780px;
        z-index: 1;
        .banner-button{
            margin-top: 2rem;
            text-align: center;
            .button{
                background-color: var(--secondary);
                display: inline-flex;
                line-height: inherit;
            }
        }
        > span {
            display: block;
            text-align: center;
            @include hero_subheading;
            &.banner-name {                
                color: #FFF;
                font-size: clamp(24px, 5vw, 71px);
       
                margin-bottom: 30px;
                text-transform: uppercase;
			    margin-top: 0;
			    margin-bottom: 20px;
			    line-height: 1.25;
                font-family: var(--font-primary);
                font-size: 5vw;
                font-weight: bold;
            }
        }
	}
	.banner-navigation {
		display: none; /* shown with js */
		position: absolute;
		left: 0px;
		bottom: 0px;
		width: 100%;
		z-index: 1;
	}
	.banner-navigation-body {
		text-align: center;
		width: 100%;
		padding: 10px 30px;
		.banner-nav-item {
			display: inline-block;
			width: 20px;
			height: 20px;
			background-color: #FFF;
			border-radius: 50%;
			margin: 5px 10px;
			transition: background-color $quick ease;
			> span {
				display: none;
			}
			&.active,
			&:hover {
				background-color: var(--primary-1);
			}
		}
	}
}

.contact-before-content{
    margin-top: 4rem;
}