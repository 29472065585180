@import "video.js/src/css/video-js";

// overrides
.video-js {
    &.vjs-paused .vjs-big-play-button { display: block; }
    .vjs-big-play-button {
        @include absAll(0);
        margin: auto;
        border: 0;
        width: 100%;
        height: auto;
        background-color: rgba(0,0,0,0.5);
        border-radius: 0;
    }
    &.video-vimeo {
        .vimeoFrame { position: static !important; }
        .vjs-control-bar { display: none; }
    }

    .vjs-big-play-button .vjs-icon-placeholder {
        position: relative;
        display: block;
        border: 5px solid #fff;
        border-radius: 50%;
        line-height: 1;
        margin: auto;
        height: 90px;
        width: 90px;

        &:before {
            display: block;
            font-size: 80px;
            @include absAll(0);
            margin: auto;
        }
    }

    .vjs-big-play-button .vjs-icon-placeholder + .vjs-control-text { margin-top: 20px; }

    .vjs-big-play-button .vjs-control-text {
        position: static;
        display: block;
        height: auto;
        width: auto;
        font-size: 17px;
    }
}