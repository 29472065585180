.sliderAndContent {
    @include media($wide-screen) {
        .container { max-width: 1400px; }
    }
    .fd-rw {
        @include row(30px);
        align-items: center;
        
        .fd-col {
            @include media($tablet) { 
				// width: 50%; 
				// display: flex;
				// justify-content: center;
				// align-items: cw;
			}
			@include media($large-screen) { 
				width: 50%; 
				// display: flex;
				// justify-content: center;
				// align-items: cw;
			}
			
            &.fd-slider { @include mediaDown($tablet) { margin-bottom: 30px; } 
		}
        }
        &.has-slider .fd-desc {
            @include media($tablet) { margin-top: 50px; }
            // @include media($small-desktop) { margin-top: 100px; }
        }
    }

    .content-main {
        h2, h3, h4 { text-transform: uppercase; }
    }

    .cta-wrap {
        margin-top: 30px;
    }
}

.kmsgallery.display-filmstrip.product {
    .kmsgallery-thumbnail-image {
        background-color: var(--dimgray);
    }
	.kmsgallery-stage {
		padding-top: 90%;
		> div {
			&.kmsgallery-stage-image {
				background-size: cover;
			}
			&.kmsgallery-stage-video {
				@include absAll(0);
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				background-color: var(--dimgray);
				.video-js {
					@include absAll(0);
					margin: auto;
					height: 100%;
				}
			}
		}
	}
	.kms-stage-description {
		padding: 10px 0px;
	}
	.kmsgallery-filmstrip {
		.kmsgallery-thumbnail-image,
		.kmsgallery-thumbnail-video {
			width: 160px;
			height: 0px;
			padding-top: 25%;
			margin: 0px 5px;
			box-sizing: border-box;
		}
		.kmsgallery-thumbnail-video {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			background-color: var(--primary-1);
			.overlay-video-thumb {
				@include absAll(0);
				display: flex;
			    flex-wrap: wrap;
			    align-items: center;
			    justify-content: center;
			    background-color: rgba(0,0,0,.35);
			    span {
			        display: block;
			        width: 100%;
			    }
			    .vjs-icon-play {
			        margin-bottom: 10px;
			        &::before {
			            border-radius: 50%;
					    border: 2px solid #fff;
					    font-size: 35px;
			        }
			    }
			}
		}
	}
}