.banner-block  {
	position: relative;
	background-color: #000;
	min-height: 800px;
	min-height: 80vh;
	.banners {
		position:absolute;
	    top:0;
	    left:0;
	    right:0;
	    bottom:0;
	    .banner-stage {
            display: flex;
            height: 100%;
	    }
	    a.banner-image {
	        color: #FFF;
	        text-decoration: none;
			height:100%;
		    flex-basis: 60px;
		    position: relative;
		    transition:flex 1s ease;
		    z-index: 0;
			img{
				object-fit: cover;
				height: 100%!important;
				width: 100%!important;
				position: absolute;
				z-index: -1;
				inset: 0;
			}
		    &::before { /* darken */
				content:"";
			    display: block;
			    width: 100%;
			    height: 100%;
			    position: absolute;
			    top: 0px;
			    left: 0px;
			    background-color: #000;
			    opacity: 0.6;
		    }
		    &.active {
		        flex-grow: 1;
		        z-index: 1;
		        .banner-name,
		        .banner-caption {
	                opacity: 1;
	                transition: opacity 0.3s linear 1s;
			    }
		    }
		    &:nth-of-type(even){
		        background-color: var(--dimgray);
		    }
	    }
	    a[href]{
	        .banner-content {
	            > span.post-content {
	                display: block;
	                padding: 20px 0px;
	                opacity: 0;
	            }
	        }
	        &.active {
	            .banner-content > span.post-content {
	                opacity: 1;
	                transition: opacity 0.3s linear 1s;
	            }
	        }
	    }
	    .banner-content {
	        position: absolute;
	        top: 0px;
	        left: 0px;
	        width: 100%;
	        height: 100%;
	        padding: 10%;
	        box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: center;
	        > span {
	            display: block;
	            &.post-content {
	                display: none;
	            }
	        }
	    }
	    .banner-name,
        .banner-caption {
            opacity: 0;
        }
        .banner-title { // this is displayed vertically
			transform: rotate(-90deg);
		    display: block;
		    position: absolute;
		    left: 10px;
		    bottom: 0;
		    transform-origin: top left;
		    font-weight: bold;
		    font-size: 24px;
		    white-space: nowrap;
	    }
	    .banner-name {
	        font-size: 4vw;
            font-weight: bold;
	    }
		.banner-caption {
			font-size: 20px;
			font-weight: 500;
		}
		.banner-navigation {
		    display:none;
		}
	}
}

@media only screen and (max-width: 760px){
	/* flip it vertical */
	.banner-block  {
		.banners {
			.banner-stage {
				flex-direction: column;
			}
			.banner-content {
				position: relative;
				padding: 0px;
			}
			a.banner-image {
				overflow: hidden;
				&.active {
					.banner-content {
						padding: 40px 30px;
						padding-bottom: 60px;
					}
				}
			}
			.banner-title {
				transform: rotate(0);
				bottom: 10px;
			}
			.banner-caption {
				font-size: 15px;
			}
		}
	}
}