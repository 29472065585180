.featuredImage {
    .image {
        position: relative;
        overflow: hidden;
        height: 0;
        padding-top: 70%;
        @include media($medium-screen) { padding-top: 55%; }
        @include media($tablet) { padding-top: 50%; }
        @include media($small-desktop) { padding-top: 40%; }
        @include media($desktop) { padding-top: 33%; }
    }

    img {
        @include imgBg();
    }
}