.section-module {
    padding: 5em 0;
    @include media($desktop) { padding: 6.5em 0; }

    &.noSpacing, &.noSpace { padding: 0; }
    &.noTop { padding-top: 0; }
    &.noBottom { padding-bottom: 0; }
}

@import 'modules/usps';
@import 'modules/headingsBannerImage';
@import 'modules/feature-list';
@import 'modules/contentImage';
@import 'modules/testimonialSlider';
@import 'modules/accordion';
@import 'modules/textContent';
@import 'modules/featureItemSwiper';
@import 'modules/infopackDownload';
@import 'modules/iconContentColumns';
@import 'modules/masonryContents';
@import 'modules/whitespace';
@import 'modules/inlineThumbnailListing';
@import 'modules/sliderAndContent';