.accordion-section {
    &.accordion-with-image{
        .accordion-title{
            text-align: center;
        }
        .container{
        
            .flex{
                align-items: center;
               
                position: relative;
                @media screen and (max-width:768px) {
                    flex-direction: column;
                    gap:2rem;
                    .image-frame{
                        // min-height: 300px;
                        width: 100%;
                    }
                    .col{
                        flex-basis: 100%;
                        width: 100%;
                    }
                }
            }
            .image-frame{
                min-height: clamp(400px, 40vw, 600px);
                display: flex;
                justify-content: center;
                align-items: center;
                .image{
                    position: absolute;
                    img{
                       
                        opacity: 0;
                        transition: opacity 1s ease-in-out;
                        top:0;
                        left:0;
                        
                        object-fit: contain;
                    }
                    &.show img{
                        opacity: 1;
                    }
                }
            }
            
            .accordion-item{
                .content{
                    padding:0;
                    padding-top: 1rem;
                }
            }
           
        }
    }
    .heading {
        margin-bottom: 50px;
    }
/* accordionWithImages */
    .flex.frame {
        justify-content: space-between;
    }
    .col {
        position: relative;
		flex-basis: 48%;
		box-sizing: border-box;
    }
    &.bg-blue {
        .accordion.accordion-default {
            .accordion-item {
                .header {
                    h3 {
                        color: var(--primary) !important;
                    }
                }
            }
        }
    }
    .flex-heading {
        padding-top: 40px;
    }
}