html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}
*{ 
    hyphens: none;
}
::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */
@import "vars"; //combined variables + mixins

body {
    padding:0;
    margin:0;
    font-family: var(--font-primary);
    font-size: 15px;
    color: #000;
    background-color: #fbfbfb;
    overflow-x: hidden;
}

.hidden-custom{
    opacity: 0;
    position: absolute;
    height: 0px;
    width: 0px;
}
/*
.contentwrap {
    max-width:1200px;
    margin: 0 auto;
    position:relative;
    padding: 20px;
}
header .contentwrap,
footer .contentwrap {
    padding: 0 20px;
} */

img {
	display: inline-block;
    max-width: 100%;
    border:none;

    -webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-ms-user-drag: none;
	user-drag: none;
}
a.logo {
    display: inline-block;
    width: 250px;
    max-width: 100%;
    height:auto;
    h1 {
        font-size: 1rem;
        margin: 0px;
    }
}

header a,
footer a,
.contact-details a {
    color: inherit;
}

.block { display: block; }
.relative { position: relative; }

.r { color: var(--primary-1); vertical-align: super; font-size: 0.6em; }

// vendor
@import 'vendor/vendor'; /* video banner + selectric + slider */

.container-wide {
    @include container();
    max-width: $wide-screen;
}
.container {
    @include container();
    max-width: $desktop;
}
.container-narrow {
    @include container();
    max-width: $small-desktop;
}
.container-small {
    @include container();
    max-width: $large-screen;
}
.container-xs {
    @include container();
    max-width: 850px;
}

.lazyload, .lazyloading {
    filter: blur(5px);
}
.flex {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	box-sizing: border-box;
}
.imgbg,
.bgImage {
	picture.bg {
		/* fill the wrapper div */
		position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    margin: auto;
	    img {
	        /* fill picture */
			position: relative;
			width: 100%;
			min-height: 100%;
			box-sizing: border-box;

	        -o-object-fit: cover;
		    object-fit: cover;
		    -o-object-position: center;
		    object-position: center;
		    transition: all 1s ease;
	    }
	}
	img {
		/* fill picture */
		position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 100% !important;
	    margin: auto;
		box-sizing: border-box;

        -o-object-fit: cover;
	    object-fit: cover;
	    -o-object-position: center;
	    object-position: center;
	    transition: all 1s ease;
	}
}
/** applies to list images + featureItemSwiper image **/
.imgbg.fallback {
	img {
        width: 70% !important;
        bottom: 0 !important;
        -o-object-fit: scale-down;
        object-fit: scale-down;
        margin: auto !important;
    }
    &:hover {
        img {
            transform: scale(1);
        }
    }
}

.block {
	position: relative;
	// make blue-bg extend behind enquiry if it's next
	&.blue-bg + &.enquiry::before {
	    content: '';
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: auto;
	    left: 0;
	    margin: auto;
	    display: block;
	    width: 100%;
	    height: 50%;
	    background-color: var(--primary);
	}
}

@import "editor"; //text styles
@import "menus";
@import "forms";
@import "site_links";
@import "pager";
@import "header";
@import "mobile_menu";
@import "footer";
@import "case_study";
@import 'components/accordion'; /* includes accordionWithImages */
@import 'components/banner-block'; /* aka slidingDoors */
@import 'components/filtering';
@import 'components/featuredImage';
@import 'components/entryListing';
@import 'components/slider';
@import 'components/fadedCard';
@import 'components/modules';

// Default theme
@import '@splidejs/splide/css';
@import '@splidejs/splide/css/core';

@import "glightbox/dist/css/glightbox.css";
@import "page"; /* <- includes home */
@import "contact";
@import "content_types"; /* lists: news, case-studies, fences/categories */
@import "office";
@import "product";
@import "banners";

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 760px) {

    a {

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

    }
/** form **/
}
@import "helpers";
@import "print";