/** site links **/
.site-link {
    display: inline-block;
    margin: 5px;
    img,
    svg {
        width: 30px;
        height: 30px;
        box-sizing: content-box;
    }
}