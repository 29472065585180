/*============ header ============*/
.site-header {
    .header-strip {
        font-size: 13px;
        font-family: var(--font-secondary);
        color: var(--dimgray);
        background-color: var(--gray);
        .hrow {
            @include row(15px);
            align-items: center;
            > div { height: 100%; }

            .h-social { width: 50%; }
            .h-menu {
                width: 50%;
                &.nav-links { padding: 0; }
            }

            .h-menu.nav-links, .h-search {
                display: none;
                width: 100%;
                border-top: 1px solid #e7e7e7;
            }

            .h-search {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            @include media($large-screen) {
                .h-social { width: 20%; }
                .h-menu.nav-drawers { display: none; }
                .h-menu.nav-links {
                    display: block !important;
                    width: 55%;
                }
                .h-search {
                    display: block !important;
                    width: 25%;
                }

                .h-menu.nav-links, .h-search {
                    border: 0;
                }
            }
            @include media($small-desktop) {
                .h-social { width: 15%; }
                .h-menu.nav-links { width: 65% }
                .h-search {
                    width: 20%;
                    display: block;
                }
            }
            @include media($desktop) {
                .h-social, .h-search { width: 20%; }
                .h-menu.nav-links { width: 60% }
            }
            @include media($wide-screen) {
                .h-menu.nav-links { width: 70% }
                .h-social, .h-search { width: 15%; }
            }
        }
        .h-social {
            line-height: 0;
        }
        .h-social a {
            display: inline-block;
            &:not(:first-child) { margin-left: 15px; }
            svg {
                width: 22px;
                height: 22px;
                fill: var(--gray-2);
                transition: all $quick ease;
            }
            @include aHover() { svg { fill: var(--primary-1); } }
        }

        .h-menu .nav {
            margin: 0;
            padding: 0;
            list-style-type: none;
            li > a {
                color: var(--dimgray);
                padding: 8px 15px;
                display: block;
                font-weight: normal;
                @include aHover() { color: var(--secondary-2); }
            }
            li { display: block; }
            @include media($large-screen) {
                text-align: right;
                > li { display: inline-block; }
            }
            @include media($small-desktop) {
                > li { margin-right: 10px; }
            }
        }

        .h-menu.nav-drawers {
            .nav {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                > li { display: inline-block; }
                > li:not(:first-child) { margin-left: 10px; }
            }

            .nav-drawer a {
                cursor: pointer;
                padding: 0;
                display: block;
                position: relative;
                width: 20px;
                height: 35px;
                color: transparent !important;
                &:before, &:after, span {
                    transition: all 100ms ease;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 2px;
                    background-color: var(--gray-2);
                }
                &:before {
                    top: 10px;
                }
                span {
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
                &:after {
                    bottom: 10px;
                }

                &.open {
                    span { opacity: 0; }
                    &:before, &:after {
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                    &:before { transform: rotate(45deg); }
                    &:after { transform: rotate(-45deg); }
                }
            }

            .search-drawer a {
                padding: 10px 0;
                cursor: pointer;
                svg {
                    fill: var(--gray-2);
                    width: 18px;
                    position: relative;
                    top: 3px;
                }
            }
        }

        .h-search .search-group {
            display: block;
            position: relative;
            height: 100%;

            .search-field {
                font-size: 13px;
                border: 0;
                padding: 8px 30px 8px 15px;
                background-color: #FFF;
                border-radius: var(--button-radius);
                @include placeholder() {
                    color: #c3c3c3;
                }
            }
        }
    }

    .header-big .main-header {
        min-height: 75px;
        margin-top: 2em;
        margin-bottom: 2em;

        @include row(15px);
        align-items: center;
        > .col { width: 100%; }

        .call-to-action, .main-menus {
            display: none;
            @include media($small-desktop) { display: block; }
        }

        .site-brand {
            width: 50%;
            a { display: block; }
        }
        .mobile-drawers {
            width: 50%;
            text-align: right;

            .mobile-nav-drawer {
                cursor: pointer;
                color: var(--primary-1);
                padding: 13px 15px 10px 15px;
                border: 1px solid var(--gray-1);
                font-size: 80%;
                font-weight: 600;

                svg {
                    margin-left: 5px;
                    width: 13px;
                    position: relative;
                    top: 0px;
                }
            }
        }



        @include media($small-desktop) {
            .site-brand {  width: 20%; }
            .mobile-drawers { display: none; }
            .call-to-action { width: 15%; }
            .main-menus { width: 65%; }
        }

        .col.main-menus {
            flex-grow: 1;
            min-height: 75px;
            nav {
                padding: 10px 0px;
            }
        }
        .col.main-menus .nav.main-nav {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            > li {
                width: auto;
                font-weight: 500;
                &.last {
                    text-transform: uppercase;
                    color: #FFF;
                    .button {
                        padding: 10px 20px;
                        border-radius: var(--button-radius);
                        &::before { /* rm underline */
                            display: none;
                        }
                    }
                }
                > a {
                    position: relative;
                    display: inline-block;
                    font-family: var(--font-secondary);
                    text-transform: capitalize;
                    font-size: 18px;
                    color: inherit;
                    padding: 20px;
                    &::before { /* underline */
                        content: "";
                        position: absolute;
                        left: 20px;
                        right: 20px;
                        bottom: 10px;
                        height: 1px;
                        background-color: transparent;
                    }
                    @include aHover() {
                        color: var(--secondary-2);
                        &::before {
                            background-color: var(--secondary-2);
                        }
                    }
                }
            }
            li.has-megamenu {
                > a {
                    position: relative;
                    padding-right: 30px;
                    &:after {
                        content: '';
                        @include absPos(0,15px,0);
                        margin: auto;
                        height: 6px;
                        width: 6px;
                        border-left: 2px solid;
                        border-bottom: 2px solid;
                        transform: rotate(-45deg);
                        border-color: var(--gray-2);
                    }
                }
            }
            li.normal-dropdown{
                position: relative;
                & > ul{
                    position:absolute;
                    top: 100%;
                    left:0;
                    background:#f6f6f6;
                    color:#082133;
                    z-index:10000;
                    text-align: left;
                    display:none;
                    box-shadow: 0 0 4px #444;
                   
            
                    // font-size: 18px;
                    & > li{
                        padding:1rem .5rem;
                        &:hover{
                            background-color: #082133;
                            .title{
                                color:#fff;
                            }
                        }
                    }
              
                }
                &:hover > ul{
                    display:flex;
                    flex-direction: column;
                    white-space: nowrap;
           
                }
                & > ul::after, & > ul::before {
                    content:"";
                
                    position:absolute;
                    top:0;
                    bottom:0;
                    width:0;
                    height:100%;
                }
                & > ul::before {
                    left:0;
                    padding-left:50vw;
                    margin-left:-50vw;
                   
                }
                & > ul::after {
                    right:0;
                    padding-right:50vw;
                    margin-right:-50vw;
                }
            }
        }
    }
}

/* from old theme - megamenu */
.has-megamenu:hover {
    .mega-menu { display: block; }
}
.mega-menu {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0 50px 50px 0 rgba(#333, 0.2);
    border-top: 1px solid #ccc;
    text-align: left;

    .megamenu-tab {
        display: flex;
        .tab-menus {
            display: block;
            width: 20%;
            padding: 2em 0;
            &.fw { width: 100%; }
        }
        .tab-contents {
            width: 79%;
            padding: 2.5em 3em;
        }

        .tab-menus li {
            text-align: right;
            box-sizing: border-box;
            a {
                display: inline-block;
                text-transform: uppercase;
                font-family: var(--font-secondary);
                font-weight: 500;
                padding: 10px 20px;
                min-width: 115px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                font-size: 1rem;
                &:hover {
                    color: var(--dimgray);
                }
            }
            &.active {
                > a {
                    font-weight: bold;
                    background-color: #fff;
                }

            }
        }
    }

    .mgm-items {
        @include row(10px);
        .mgm-item {
            margin-bottom: 20px;
            @include media($tablet) { width: 50%; }
        }
    }
    .megamenu-tab .mgm-items {
        &:not(.active) { display: none; }
        .mgm-item {
            @include media($large-screen) { width: 33.333333%; }
        }
    }

    .mgm-item .mgmi-wrap {
        display: flex;
        // stretch
        width: 100%;
        box-sizing: border-box;
        .ft-image {
            position: relative;
            width: 35%;
            padding-top: 22%;
            background-color: var(--dimgray);
            .bg {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                img {
                    /* fill picture */
					position: relative;
					width: 100%;
					height: 100% !important;
					box-sizing: border-box;

			        -o-object-fit: cover;
				    object-fit: cover;
				    -o-object-position: center;
				    object-position: center;
                }
            }
        }
        .info {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;

            width: 65%;
            color: inherit;
            padding: 5px 20px;
            .subtitle { font-size: 13px; }
        }

        .info h3 {
            text-transform: capitalize;
            color: var(--primary);
            font-size: 18px;
            line-height: 1;
            margin-bottom: 5px;
        }
        @include aHover() {
            background-color: var(--primary-1);
            .info, .r, .info h3 { color: #fff; }
        }
    }
}