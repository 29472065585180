.masonryContents {
    .msry-rw {
        display: none;
        @include media($medium-screen) {
            @include masonry(2, 25px);
            display: block;
        }
        @include media($small-desktop) {
            @include masonry(2, 50px);
            &.ie { @include masonry(2, 40px); }
        }

        @include mediaDown($medium-screen) {
            .msry-cl:not(.msnry-heading) { margin-bottom: 40px; }
            .msnry-heading { text-align: center; margin-bottom: 50px; }
        }

        .msnry-heading h2 {
            margin: 0;
            font-size: 60px;
            line-height: 1;
            margin: 30px 0;
        }
    }

    .mb-layout {
        @include media($medium-screen) { display: none; }

        .heading { margin-bottom: 50px; }

        .mbl-rw {
            @include row(20px);
            justify-content: center;
        }
        .mbl-rw .mbl-item {
            margin-bottom: 40px;
            width: 100%;
            @include media($medium-screen) { width: 50%; }
            @include media($large-screen) {
                width: 33.333333%;
                &.quarters { width: 25%; }
                &.thirds { width: 33.333333%; }
                &.half { width: 50%; }
                &.full { width: 100%; }
            }
        }
    }
}