/*============ footer ============*/
.info-footer-bar {
    padding-top: 5em; /* for doggies ears */
    .flex-heading{
        display: flex;
        gap:1rem;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        @include media($large-screen){
            flex-direction: row;
            align-items: flex-start;
        }
    }
    .bar-content {
        position: relative;
        color: #FFF;
        background-color: var(--primary);
        background-image: url('../assets/world.jpg');
        background-size: auto 100%;
        @include media($small-desktop){
            background-size: 50% auto;
        }
        background-position: right;
        background-repeat: no-repeat;
        padding: 5em;
        box-sizing: border-box;

        &::before { /* gradient over bg-image */
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(11,45,82,1) 50%, rgba(11,45,82,0.5) 65%, rgba(11,45,82,0) 100%);
            z-index: 0;
        }
        .bluedog {
            display: block;
            position: absolute;
            left: 60%;
            bottom: 0px;
            width: 300px;
            max-width: 100%;
            background-image: url('../assets/post.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 1;
            &:before {
                content:"";
                display: block;
                width: 100%;
                padding-top: 120%; /* height ratio */
            }
        }
    }

    .tagline-info {
        position: relative;
        width: 100%;
        padding-right: 55%;
        box-sizing: border-box;
        z-index: 2;
        h2, h3 {
            color: inherit;
        }
        .button {
            font-size: 0.8rem;
        }
    }
   
}

.site-footer {
    margin-top: 20px;
    a {
        color: inherit;
        @include aHover() { color: var(--secondary-2); }
        &.phone,
        &.email {
            text-decoration: none;
        }
        &.phone {
            &:hover {
                color: inherit;
            }
        }
        &.email {
            color: var(--primary-1);
            &:hover {
                color: var(--secondary-2);
            }
        }
    }
    .flex {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        justify-content: space-evenly;
        justify-content: space-between;
    }
    .footer-top {
        padding: 30px;
        nav {
            ul,
            li {
                display: block;
                > a {
                    padding: 10px 5px;
                }
                .subtitle {
                    display: none;
                }
            }
        }
    }
    .logos {
        order: 1; /* push to last col */

        /* push contents vertically apart */
        align-self: stretch;
        display: inline-flex;
        flex-direction: column;
        a {
           width: auto;
           margin-bottom: 10px;
        }
        img {
            width: 94px;
            max-width: 100%;
            margin: 5px;
            &.iso {
                margin-top: auto;
                padding-bottom: 20px;
            }
        }
        .footer-patners{
            margin-top:2rem;
            @media only screen and (max-width: 960px){
                margin-top:0;
                margin-left:2rem;
            }
        }
    }
    .footer-contacts {
        padding-left: 80px; /* for img */
        .info {
            padding-bottom: 30px;
            > * {
                display: block;
            }
            &.site {
				position: relative;
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 0px;
					left: -60px;
					width: 46px;
					height: 52px;
					background-image: url("../assets/aus-wide.svg");
					background-size: contain;
					background-position: center;
				}
            }
            &.office {
                .name {
                    font-weight: bold;
                    color: var(--primary-1);
                }
            }
        }
    }
    .site-links {
        display: inline-block;
        vertical-align: middle;
        width: auto;
        .site-link {
            svg {
                padding: 0px;
                line-height: 1;
                fill: var(--primary-1);
                transition: fill $quick ease;
            }
            &:hover {
                svg { fill: var(--secondary-2); }
            }
        }
    }

    .footer-bottom {
        margin: 0;
        padding: 10px 0px;

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-size: 13px;

		> span,
        li {
            margin: 10px 2.5em;
        }
    }
}


/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding: 0px;
    padding-top: 5px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
    &:hover {
        color: grey;
    }
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    transition: transform 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}


@media only screen and (max-width: 960px) {
    .info-footer-bar {
        padding-top: 0px;

        .bar-content {
            padding: 30px;
            padding-bottom: 0px;
            background-size: cover;
            .bluedog {
                position: relative;
                width: 200px;
                left: 0px;
                margin: 0 auto;
            }
            &::before {
                background: linear-gradient(90deg, rgb(11, 45, 82) 10%, rgba(11, 45, 82, 0.5) 20%, rgba(11, 45, 82, 0) 100%);
            }
        }
        .tagline-info {
            text-align: center;
            padding-right: 0px;
        }
    }

    .site-footer {
        .footer-top {
            .flex,
            .logos {
                display: block;
                text-align: center;
            }
            nav {
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding-top: 10px;
                }
                li a {
                    padding: 10px;
                }
            }
        }
        .logos {
            > * {
                display: inline-block;
                vertical-align: middle;
            }
            img {
                width: 80px;
                &.iso {
                    padding-bottom: 0px;
                    margin-bottom: 10px;
                }
            }
        }
        .footer-contacts {
            padding-left: 0px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .info {
                flex-grow: 1;
                width: 30%;
                padding: 10px;
                box-sizing: border-box;
                &.site {
                    &::before {
                        position: relative;
	                    left: 0px;
	                    margin: 0 auto;
	                    margin-bottom: 10px;
                    }
                }
                &.office {
                    &::before {
                        content: "";
                        display: block;
                        padding-top: 62px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 760px) {
	.site-footer {
		.footer-contacts {
			padding-bottom: 10px;
			.info {
				flex-grow: 1;
				width: auto;
				&.site {
					width: 100%;
				}
				&.office {
					font-size: 13px;
					&::before {
						display: none;
					}
				}
			}
		}
	}
}