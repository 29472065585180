.gslide-image img {
    aspect-ratio: 3/4;
    height: 97vh!important;
    object-fit: cover;
}

.glightbox-mobile{
    .gslide-description{
        background-color: rgba(2,2,2,.7)!important;
    }
    .gslide-image img {
        aspect-ratio: unset;
        width: 97vw!important
    }
}
.t-office-item{
    .gslide-description{
        text-decoration: none;
        max-width: 30vw!important;
    }
    .page-title{
        font-weight: 800;
    }
    .subheading{
        font-size: 20px;
        max-width: 950px;
        margin: 0 auto;
    }
    .quote-section{
        background-color: #EEF1F5;
        .container{
            display: flex;
            justify-content: space-evenly;
            align-items: flex-end;
            height: 100%;
            min-height: 300px;

            .image{
                display: block;
                position: relative;
                width: clamp(150px, 20%, 350px);
                min-width: 150px;
                // height: 115%;
                // margin-top: -15%;
                z-index: 10;
                img{
                    width: 100%;
                    object-fit: contain;
                    object-position: bottom;
                    position: absolute;
                    display: block;
                    bottom: 0;
                    left: 0;
                    z-index: 1;

                }
            }
            .content{
                max-width: 750px;
                align-self: center;
                blockquote{
                    font-size: 20px;
                }
            }
            @media screen and (max-width:576px) {
                flex-direction: column-reverse;
                align-items: flex-start;
                .image{
                    img{
                        position: relative;
                        // margin-right: auto;
                    }
                }
            }
        }
        
    }
    .content-main{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 4rem 0;
        gap:1rem;
        .contact-table{
            text-align: left;
            display: flex;
            justify-content: center;
            align-items: center;
            th{
                font-weight: 700;
                padding-right: 1rem;
            }
        }
        @media screen and (max-width:576px) {
            grid-template-columns: 1fr;
        }
    }
    .additional-content{
        padding-top: 4rem;
        padding-bottom: 4rem;
        background-color: #EEF1F5;
    }
    .contact-person{
        padding-top:4rem;
        padding-bottom:4rem;
        .section-title{
            max-width: 500px;
        }
        .two-col{
            display: flex;
            align-items: center;
            gap:2rem;
        
            & > * {
                width: 50%;
            }
            
            .staff-title{
                color:var(--primary);
                margin-bottom: 0;
                font-size: 22px;
            }
            .staff-position{
                margin-top: 0;
                font-size: 18px;
                font-weight: 700;
            }
            .image-section{
                display: flex;
                justify-content: center;
                padding:0px 40px;
                margin: 2rem 0;
                picture{
                    display: block;
                    position: relative;
                    border-radius: 50px;     
                    width: 100%;           
                    img{
                        border-radius: 50px;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                        max-width: 480px;
                        background-color: #EEF1F5;
                    }
                    &::after{
                        content:'';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        height: calc(100% + 10%);
                        margin: auto;
                        width: 25%;
                        background-color: var(--primary-1);
                        z-index: 0;
                        left: auto;
                        right: -20px;
                        z-index: -1;
                    }
                }
            }
            @include mediaDown($tablet) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                gap:0;
                & > * {
                    width: 100%;
                }
            }
        }
    }
    .staff-list{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap:2rem;
        justify-content: center;
        padding-top:2rem;
        padding-bottom:4rem;
        @media screen and (max-width:768px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width:576px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width:400px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .card{
            width: 100%;
            position: relative;
            border: 1px solid #ccc;
            border-radius: 20px;
            overflow: hidden;
            img{
                width: 100%;
                aspect-ratio: 1/1;
                height: 100%!important;
                object-fit: cover;
            }
            .default{
                img{
                    object-fit: contain;
                    padding: 1rem;
                }
            }
            .info{
                position: absolute;
                bottom:0;
                left:0;
                width: 100%;
                font-weight: 700;
                background-color: #EEF1F5;
                color: var(--primary);
                padding:10px;
            }
            
        }
        
    }
    .site-footer{
        margin-top: 0px;
    }
}
.t-office-index{
    .office-list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap:2rem 1rem;
        @media screen and (max-width:756px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width:500px) {
            grid-template-columns: 1fr;
        }
        .office{
            display: flex;
            flex-direction: column;
            text-align: center;
            gap:2rem;
            padding-bottom: 2rem;
            .phone {
                font-size: 1.5em;
            }
        }
        .office-content{
            display: flex;
            flex-direction: column;
            gap:1rem;
            padding:0 20px;
        }
        .image{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            &.default{
                picture{
                    padding: 1rem;
                    img{
                        object-fit: contain;
                    }
                }
             
            }
            picture{
                display: block;
                width: 100%;
                aspect-ratio: 4/3;
                background-color: rgba(0,0,32,.39);
            }
            img{
                width: 100%;
                height: 100%!important;
                mix-blend-mode: multiply;
              
                object-fit: cover;
              
            }
            .title{
                position: absolute;
                color:#fff;
                margin:0;
            }
        }
    
    }
}
