

// Any non-block module slider
.thumb-slider {

    .swiper-container.gallery-top .sw-slider-image, .sw-slider-video {
        padding-top: 90%;
    }
    .swiper-container.gallery-thumbs .swiper-slide {
        padding-top: 25%;
    }

    .swiper-slide {
        display: block;
        background-size: cover;
        background-position: center;

        .sw-slider-image, .sw-slider-video {
            position: relative;
            height: 0;
        }

        .sw-slider-video {
            background-color: #000;
        }

        img {
            @include imgBg();
        }

        .video-js {
            @include absAll(0);
            margin: auto;
            height: 100%;
        }

        &.mfc-video .v-overlay {
            @include absAll(0);
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.35);
            z-index: 1;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            color: #fff;
            svg {
                width: 100px;
                display: block;
            }
            span {
                width: 100%;
                display: block;
                margin-top: 20px;
            }
        }
    }

    .gallery-top + .gallery-thumbs {
        padding-top: 15px;
    }

    .gallery-thumbs {
        .swiper-slide {
            width: 25%;
            height: 100%;
            opacity: 0.4;
        }
        .swiper-slide-thumb-active {
            opacity: 1;
        }

        .overlay-video-thumb {
            @include absAll(0);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            background-color: rgba(#000, 0.35);
            span {
                display: block;
                width: 100%;
            }
            .vjs-icon-play { margin-bottom: 10px; }
            .vjs-icon-play:before {
                border-radius: 50%;
                border: 2px solid #fff;
                font-size: 35px;
            }
        }
    }

}