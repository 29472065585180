/** old theme **/
body.nav-open {
    overflow: hidden;
    &:before {
        content: '';
        @include absAll(0);
        background-color: rgba(#000, 0.5);
        z-index: 10;
        transition: all 300ms ease;
    }
}

.mobileMenu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    height: 100%;
    width: 350px;
    max-width: 100%;
    background-color: var(--gray);
    transform: translateX(-100%);
    transition: all 300ms ease;
    overflow-y: auto;

    &.open { transform: translateX(0); }

    .mbm-header {
        position: relative;
        padding: 30px 20px;
        .brand {display: inline-block; }
        img { max-width: 200px; }
    }

    .mbm-search {
        padding: 0 30px;
        margin-bottom: 20px;
        form {
            position: relative;
        }
        input {
            background-color: var(--gray-1);
        }
        button[type=submit] {
            all: unset;
            width: 30px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: 100%;
            overflow: hidden;
            cursor: pointer;
            svg {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                width: 15px;
                fill: var(--gray-2);
                transition: all $quick ease;
            }
            @include aHover() { svg { fill: var(--primary-1); } }
        }
    }

    .close-mobileMenu {
        display: block;
        cursor: pointer;
        width: 30px;
        height: 30px;
        border: 1px solid #fff;
        background-color: #fff;
        @include absPos(0,0,auto,auto);
        &:before, &:after {
            content: '';
            @include absAll(0);
            height: calc(100% - 10px );
            width: 2px;
            margin: auto;
            background-color: var(--gray-2);
        }
    }
    .close-mobileMenu:before {
        transform: rotate(45deg);
    }
    .close-mobileMenu:after {
        transform: rotate(-45deg);
    }

    .mbm-menus {
        font-weight: 600;
        li {
            position: relative;
            display: block;
        }
        li a {
            display: block;
            padding: 12px 30px;
            color: #000;
            @include aHover { color: var(--primary-1); }
            .subtitle {
                display: none;
            }
        }
        .has-sub {
            > a { border-bottom: 1px solid var(--gray); }
            ul {
                display: none;
                padding-left: 15px;
            }

            &.open {
                background-color: #fff;
                > .sub-drawer:before { display: none; }
            }
        }
        .sub-drawer {
            cursor: pointer;
            display: block;
            height: 40px;
            width: 40px;
            margin-left: 0;
            // background-color: #ccc;
            @include absPos(0,0);
            &:before, &:after {
                @include absAll(0);
                content: '';
                height: 25%;
                width: 2px;
                background-color: var(--primary-1);
                margin: auto;
            }
            &:after { transform: rotate(90deg); }
        }
    }

    .mbm-footer {
        padding: 15px 30px;
        .site-links a {
            display: inline-block;

            &:not(:first-child) { margin-left: 10px; }
            svg {
                width: 30px;
                height: 30px;
                fill: var(--primary-1);
            }
        }
    }
}

