
/*============ Contact ============*/
/** formerly enquiry.scss **/
.section-module.enquiry {
    position: relative;
    .wrap-for-space { position: relative; }
    &.layout-box {
        .box-wrap { 
            position: relative; 
            background-repeat: no-repeat;
            background-size: 50% 100%;
            background-position: left;
        }
        .box-container {
            position: relative;
            @include container(clamp(20px,5vw,80px));
            // @include media($wide-screen) { width: $desktop; }
    
        }
    }

    img.bg {
        @include imgBg();
        height: 100% !important;
        opacity: 0.25;
    }
    .bgImage { opacity: 0.25; }

    .container { position: relative; }

    .pe-rw {
        @include row(20px);
        align-items: normal;
        justify-content: center;
        flex-direction: column;
        gap:2rem;
        // @include media($desktop) {
        //     flex-direction: column;
        //     align-items: center;
        //     .pe-cl{
        //         width: 100%;
        //     }
        // }
     
        @include media($desktop) {
            flex-direction: row;
            align-items: normal;
            gap:0;
            .pe-cl.contents { width: 60%; }
            .pe-cl.ff-enquiry { 
                width: 40%; 
                padding:30px 0;
            }
        }
      
        
    }

    .contents {
        color: #000;
        position: relative;
        display: flex;
        // flex-wrap: wrap;
        @media screen and (max-width:992px) {
            flex-direction: column;
            gap:2rem;
            align-items: center;
     
        }
        @media screen and (max-width:500px) {
            gap:1rem;
        }
        .contact-dog{
            margin-top: -40px;
            width: clamp(100px, 30vw, 234px);
            object-fit: cover;
            overflow: visible;
            z-index: 1000;
            position: relative;
            @media screen and (max-width:992px) {
                margin-top: max(-5vw,-25px);
            }
        }
        .dog-contact-content{
            color:#fff;
            padding:20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;
        }
        .button{
            // margin-top: 2rem;
            display: inline-block;
            width: fit-content;
            padding:18px 20px;
            border-radius: 20px;
        }
        .row{
            margin-top: 2rem;
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            justify-content: center;
            @include media($large-screen) { 
                justify-content: flex-start;
            }
        }
        .pec-wrap {
            margin-bottom: 30px;
            
            @include media($large-screen) { margin-bottom: 0; }
        }
        
        h2 {
            color:#fff;
            text-transform: uppercase;
            @include media($tablet) { font-size: 50px; }
            @include media($large-screen) { font-size: 55px; }
            @include media($small-desktop) { font-size: 60px; }
        }
        p { margin-bottom: 5px; }
        // a { color: var(--primary-1); }
        font-size: 17px;
    }

    .ff-enquiry {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
            color:white;
        }
        p {
            color:white;
            text-align: center;
        }
        .selectric, .form-control {
            background-color: var(--gray-1);
            border: 0;
        }
        .form-control, .selectric .label {
            padding: 15px 20px;
            margin: 0;
            @include media($large-screen) { padding: 18px 20px; }
        }
        .btn[type=submit] {
            border: 0;
            min-width: 180px;
            margin-top: 1em;
            padding: 15px 20px 14px 20px;
            border-radius: 20px;
            @include media($large-screen) { padding: 18px 20px 17px 20px; }
            @extend .btn-secondary;
        }
        
    }

    .ff-enquiry button[type=submit] {  
        @extend .btn-secondary; 
    }

    &.bg-dimgray, .bg-dimgray,
    &.bg-blue, .bg-blue,
    &.bg-deepblue, .bg-deepblue {
        .contents { color: #fff; }
        .contents a { color: var(--secondary); }
        .selectric, .form-control {
            background-color: rgba(#fff, 0.25);
            border: 0;
        }
        .form-control, .selectric .label {
            color: #fff;
            @include placeholder() { color: var(--gray); }
        }
    }
    &.bg-blue, .bg-blue, &.bg-deepblue, .bg-deepblue {
        .ff-enquiry button[type=submit] {  @extend .btn-secondary; }
    }
    &.bg-orange, .bg-orange {
        .contents { color: #fff; }
        .contents a { color: var(--primary); }
        .ff-enquiry {
            .form-control, .selectric .label {
                color: #000;
                @include placeholder() { color: #000; }
            }
        }
    }

    &.bg-orange, .bg-orange, &.bg-gray, .bg-gray {
        .selectric, .form-control { background-color: #fff; }
    }
}

.hero.w-fullwidth + .enquiry {
    margin-top: -10em;
    @include media($desktop) { margin-top: -13em; }
}

/** formerly contactForm.scss **/
.contact-section {
    .content-wrapper {
        padding: 5em 1em;
        background-color: var(--gray);
    }

    form {
        display: block;
        width: 100%;
        margin: auto;
        @include media($large-screen) { max-width: $large-screen; }

        button[type=submit] {  @extend .btn-secondary; }
    }

    .form-row {
        @include row(15px);

        @include media($medium-screen) {
            .col.half { width: 50%; }
        }

    }

    .form-status {
        padding: 15px 20px;
        border: 1px solid;
        margin-bottom: 20px;
        border-radius: 5px;
        background-color: #fff;
        font-weight: 600;

        > li:not(:first-child) { margin-top: 5px; }

        &.success {
            border-color: var(--secondary);
            background-color: rgba(var(--secondary),0.25);
        }

        &.errors {
            border-color: red;
            background-color: rgba(red,0.25);
        }
    }
}

/** new **/
.t-contact .section-module.enquiry .contents {
    color: #fff;
	h2 {
		color: #fff;
        font-weight:800;
	}
    
}
a.tel {
	white-space: nowrap;
}
.opening-hours {
    display: block;
}

.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    aspect-ratio: 1/1;
    margin-top: auto;
    & > *{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.kms-loader {
    transform: scale(.5);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50%;
    margin-left: -50%;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}

.contact-dog{
    height: 100%;
}
