.contentImage {
    position: relative;
    z-index: 1;
    @include media($large-screen) {
        &.has-backdrop .ci-image:after { content: ''; }
    }

    .wrap-for-space { position: relative; }

    .flex {
        display: block;

        @include media($large-screen) {
            display: flex;
            align-items: center;
            .ci-content,
            .ci-image {
                width: 50%;
                box-sizing: border-box;
            }
            /* image right on desktop by default */
            .ci-image {
                order: 1;
            }
            .ci-content {
                padding-right: 20px;
            }
        }
    }
    .ci-content {
        padding-top: 5em;
        padding-bottom: 5em;
        .content-wrap {
            @include media($small-desktop) {
                max-width: 90%;
            }
            @include media($wide-screen) {
                max-width: 80%;
            }
        }
    }
    .ci-image {
        position: relative;
        &:after { /* blue bg block */
            @include absPos(0, 0, 0);
            height: calc(100% + 10em);
            margin: auto;
            width: 20%;
            background-color: var(--primary-1);
            z-index: 0;
        }
        .bgImage {
            position: relative;
            overflow: hidden;
            z-index: 1;
            &:before {
                content: "";
	            display: block;
	            position: relative;
	            width: 100%;
	            padding-top: 70%; /* set ratio */
	        }
	        picture.bg {
                border-radius: 50px;
	            background-color: var(--dimgray);
                img {
                    border-radius: 50px;
                }
	        }
        }
        @include mediaDown($large-screen) {
          
            display: flex;
            justify-content: center;
            .bgImage{
                margin:0 auto!important;
            }
            
        }
    }
}

/** control the position by the number in the page's **/
.related-blocks {
    aside:nth-of-type(even){
        .ci-image .bgImage{
            margin-left: auto;
        }
        .ci-image::after {
            right: -10%;
            left: auto;
        }
    }
	aside:nth-of-type(odd){
        background-color: #EEF1F5;
		.contentImage {
			.flex {
				/* image left  on desktop */
				@include media($large-screen) {
					.ci-image {
						order: 0;
					}
					.ci-content {
						padding-left: 20px;
						padding-right: 0px;
					}
				}
                @include mediaDown($large-screen) {
                    .ci-image{
                        display: flex;
                        justify-content: center;
                    }
                }
			}
			/* blue bar left too */
			.ci-image::after {
				right: auto;
				left: -10%;
			}
			/* content padding + margin on left */
			.ci-content {
		        .content-wrap {
		            @include media($small-desktop) {
		                margin-left: auto;
	                }
		            @include media($wide-screen) {
		                margin-left: auto;
	                }
                    @include mediaDown($large-screen) {
                        text-align: center;
                    }
		        }
		    }
		}
	}
}